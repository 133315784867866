import * as Sentry from "@sentry/react"
import type { ErrorInfo, ReactNode } from "react"
import { Component } from "react"

interface ErrorBoundaryProps {
	readonly children: ReactNode
}

class ErrorBoundary extends Component<ErrorBoundaryProps> {
	componentDidCatch(error: Error, _: ErrorInfo) {
		Sentry.captureException(error)
	}

	render() {
		return this.props.children
	}
}

export default ErrorBoundary
