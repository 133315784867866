import { logger } from "@/lib/utils"
import { getSelectedOrg } from "@/utils/utils"
import axios from "axios"
import { isEqual, noop } from "lodash"
import type { MicroApp } from "qiankun"
import { useCallback, useEffect, useRef, useState, type RefObject } from "react"
import { GRAFANA_PROVIDER_VIEWPORT_ID, VITE_GRAFANA_API_URL } from "./consts"
import { useGrafanaContext } from "./grafana-context"
import { GrafanaUtils, type LoadAppOptions } from "./utils"

export type UseGrafanaDashboardOptions = Partial<
	Pick<
		LoadAppOptions,
		| "entry"
		| "fnError"
		| "hiddenVariables"
		| "name"
		| "queryParams"
		| "slug"
		| "version"
	>
> & {
	uuid?: string | null
	containerRef?: RefObject<HTMLElement> | null
	isQueryParamsRequired?: boolean
}

const useGrafanaDashboard = () => {
	const {
		grafanaPortal,
		action,
		grafanaError,
		grafanaUtils: utils,
	} = useGrafanaContext()

	const grafanaUtils = useRef(
		new GrafanaUtils(err => {
			if (!action || grafanaError === err) {
				return
			}

			const error = err instanceof Event ? err.type || "" : err

			action({
				type: "SET_GRAFANA_ERROR",
				payload: error,
			})
		}),
	)

	useEffect(() => {
		if (!action) {
			return
		}

		action({
			type: "SET_GRAFANA_UTILS",
			payload: grafanaUtils.current,
		})
	}, [action])

	useEffect(() => {
		if (
			isEqual(grafanaPortal, utils?.appData?.props) ||
			!utils?.appData?.props
		) {
			return
		}
		utils.update(grafanaPortal).catch(err => {
			logger.error("Failed to update grafana app", err)
		})
	}, [grafanaPortal])

	return {
		loadGrafanaApp: utils?.load ?? grafanaUtils.current.load,
	}
}

/**
 *
 * @param uid - Grafana dashboard uid
 * @returns isError - true if dashboard with provided uid doesn't exist.
 *
 * Additionally, if user is not authenticated, it will logout the user
 */
export const useValidateDashboardUid = (uid: string | undefined) => {
	const [isError, setIsError] = useState(false)

	useEffect(() => {
		if (!uid) {
			return
		}

		axios
			.get(`${VITE_GRAFANA_API_URL}/grafana/check-dashboard/${uid}`, {
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
					"x-coderabbit-organization": getSelectedOrg()?.id,
				},
			})
			.catch(() => {
				setIsError(true)
			})
	}, [uid])

	return isError
}

function storeAppMountedFlag(name: string) {
	window.localStorage.setItem("grafana-app-loading", name)
}

export const useRootGrafanaApp = (containerRef: HTMLElement | null) => {
	const { action, grafanaProvider } = useGrafanaContext()

	const { loadGrafanaApp } = useGrafanaDashboard()

	const isRootAppLoaded = useRef(false)

	const load = useCallback(
		(isLoaded = false) => {
			if (
				!containerRef ||
				isRootAppLoaded.current ||
				!grafanaProvider ||
				isLoaded
			) {
				return
			}

			try {
				storeAppMountedFlag(grafanaProvider.name)
				loadGrafanaApp(grafanaProvider)
				isRootAppLoaded.current = true
			} catch (error) {
				logger.error("Failed to load grafana app", error)
				action?.({
					type: "SET_GRAFANA_ERROR",
					payload: error,
				})
				isRootAppLoaded.current = false
			}
		},
		[action, containerRef, loadGrafanaApp, grafanaProvider],
	)

	return {
		load,
	}
}

export declare type GrafanaRootAppStatus =
	| "BOOTSTRAPPING"
	| "LOAD_ERROR"
	| "LOADING_SOURCE_CODE"
	| "MOUNTED"
	| "MOUNTING"
	| "NOT_BOOTSTRAPPED"
	| "NOT_LOADED"
	| "NOT_MOUNTED"
	| "SKIP_BECAUSE_BROKEN"
	| "UNLOADING"
	| "UNMOUNTING"
	| "UPDATING"

interface CheckRootAppMountStatusOptions {
	onSuccess?: () => void
	onError?: (status?: GrafanaRootAppStatus) => void
	isLoading?: () => void
}

export const LOADING_STATUS: GrafanaRootAppStatus[] = [
	"LOADING_SOURCE_CODE",
	"BOOTSTRAPPING",
	"UNMOUNTING",
	"UNLOADING",
	"MOUNTING",
	"UPDATING",
]

const BROKEN_STATUS: GrafanaRootAppStatus[] = [
	"NOT_MOUNTED",
	"SKIP_BECAUSE_BROKEN",
	"LOAD_ERROR",
	"NOT_LOADED",
	"NOT_BOOTSTRAPPED",
]

const DELAY = 3000
const MAX_RETRY = 3

/**
 *
 * Check if root grafana app is mounted otherwise try to reload it 3 times.
 */
export const useRootAppStatus = () => {
	const { grafanaUtils, grafanaProvider } = useGrafanaContext()

	const getAppStatus = useCallback(
		(callback: (app?: MicroApp, appStatus?: GrafanaRootAppStatus) => void) => {
			if (grafanaUtils?.appData?.app) {
				const { app } = grafanaUtils.appData

				grafanaUtils.log.info("Grafana Root App Status", app.getStatus())
				callback(app, app.getStatus())
			}
		},
		[grafanaUtils],
	)

	const checkRootAppMountStatus = useCallback(
		(
			{
				onSuccess = noop,
				onError = noop,
				isLoading = noop,
			}: CheckRootAppMountStatusOptions,
			retry = 0,
		) => {
			const waitAndRetry = async () => {
				await new Promise(resolve => {
					setTimeout(resolve, DELAY)
				})

				if (retry > MAX_RETRY) {
					logger.error("Grafana root app is not mounted", `Retry: ${retry}`)
					onError()

					return
				}

				checkRootAppMountStatus({ onSuccess, onError }, retry + 1)
			}

			getAppStatus(async (_, status) => {
				if (status?.length && BROKEN_STATUS.includes(status)) {
					logger.info("Grafana root app is not mounted", status)

					grafanaUtils?.unload().catch(err => {
						logger.error("Failed to unload grafana root app", err)
					})

					if (grafanaProvider) {
						logger.info("Re-trying to load grafana root app")
						const newRootAppDiv = document.createElement("div")
						newRootAppDiv.id = GRAFANA_PROVIDER_VIEWPORT_ID
						newRootAppDiv.hidden = true
						document.getElementById("root")?.appendChild(newRootAppDiv)

						grafanaUtils?.load({
							...grafanaProvider,
							container: newRootAppDiv,
						})
						isLoading()
						await waitAndRetry()
					} else {
						logger.warn("Grafana provider is missing")
						onError(status)
					}

					return
				}

				if (status?.length && LOADING_STATUS.includes(status)) {
					logger.info("Grafana root app is loading", status)
					isLoading()

					await waitAndRetry()

					return
				}

				if (status === "MOUNTED") {
					logger.info("Grafana root app is already mounted")

					onSuccess()
				}
			})
		},
		[getAppStatus, grafanaProvider, grafanaUtils],
	)

	return { getAppStatus, checkRootAppMountStatus }
}
