import React from "react"
import { CgSpinner } from "react-icons/cg"

interface ButtonRegularProps {
	readonly title: string
	readonly onClick?: () => void
	readonly disabled?: boolean
	readonly loading?: boolean
	readonly type?: "button" | "reset" | "submit"
}

const ButtonRegular: React.FC<ButtonRegularProps> = ({
	title,
	onClick,
	loading,
	disabled,
	type,
}) => {
	return (
		<button
			type={type}
			onClick={onClick}
			disabled={loading || disabled}
			className={`m-2 flex items-center rounded-lg bg-crb-primary px-4 py-2 text-sm font-semibold text-white transition hover:bg-crb-primary-dark focus:outline-none focus:ring-4 focus:ring-[#ff8e6252] disabled:bg-gray-200 disabled:text-gray-400 dark:focus:ring-[#ff8e6252]`}
		>
			{loading && <CgSpinner className="mr-2 animate-spin text-xl" />} {title}
		</button>
	)
}

export default ButtonRegular
