import { Divider } from "@mui/material"
import type { ObjectFieldTemplateProps } from "@rjsf/utils"
import type { FC } from "react"
import { changeToTitleCase, getNiceNameAndDescription } from "../utils"

type Texts<T extends string> = {
	[P in T]?: string
}

export const ObjectFieldTemplate: FC<
	ObjectFieldTemplateProps & Texts<"description" | "name">
> = props => {
	const { niceDescription, niceName } = getNiceNameAndDescription(
		props.schema.description,
	)
	const label = changeToTitleCase(props.title.replace(/_/g, " "))

	return (
		<>
			<div
				className="gap-4 pb-2 pt-2 sm:flex sm:flex-col lg:grid"
				style={{ gridTemplateColumns: "0.8fr 1.5fr" }}
			>
				<div className="font-500 col-span-1 flex gap-3 font-inter not-italic leading-5">
					<div className="font-inter">
						{props.name || niceName || label}
						{props.required && <span className="text-red-500">*</span>}
						{(niceDescription || props.description) && (
							<p className="max-w-3xl pt-1 text-sm font-light not-italic leading-[20px] text-muted-foreground">
								{props.description || niceDescription}
							</p>
						)}
					</div>
				</div>
				<div className="flex flex-col gap-3">
					{props.properties.map(prop => {
						return <div key={prop.content.key}>{prop.content}</div>
					})}
				</div>
			</div>
			<Divider sx={{ mt: 2 }} />
		</>
	)
}
