import { logger } from "@/lib/utils"
import { useMutation } from "@tanstack/react-query"
import {
	isSignInWithEmailLink,
	signInWithEmailLink,
	type User,
} from "firebase/auth"
import { noop } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { firebaseAuth } from "./firebase.js"
import { getTenantId } from "./provider.js"
import { LOCAL_STORAGE_EMAIL_KEY } from "./SignInWithEmailLinkForm.js"

export const useCheckAuth = () => {
	const checkFirebaseAuth = useCallback(
		(cb: (user: User | null, token?: string) => void = noop) => {
			const unsubscribe = firebaseAuth.onAuthStateChanged(async user => {
				logger.info("Auth state changed: ", !!user)

				if (!user) {
					logger.info("No firebase user found")
					cb(null)
					return
				}

				const updateToken = async () => {
					const token = await user.getIdToken()
					cb(user, token)
				}

				await updateToken().catch(error => {
					logger.error("Failed to get token: ", error)
					cb(user)
				})
			})

			return unsubscribe
		},
		[],
	)

	return { checkFirebaseAuth }
}

export const useCompleteSignInWithEmailLink = () => {
	const { mutate: completeFlow, data: res } = useMutation({
		mutationFn: async (email: string) => {
			firebaseAuth.tenantId = getTenantId()
			const res = await signInWithEmailLink(
				firebaseAuth,
				email,
				window.location.href,
			)
			return res
		},
	})
	const [askToConfirmEmail, setAskToConfirmEmail] = useState(false)
	const completeSignIn = ({ email }: { email: string }) => {
		completeFlow(email, {
			onSuccess: () => {
				logger.info("Sign-in with email link complete")
				setAskToConfirmEmail(false)
				window.localStorage.removeItem(LOCAL_STORAGE_EMAIL_KEY)
			},
			onError: error => {
				toast.error("Failed to complete sign-in with email link")
				logger.error("Failed to complete sign-in with email link: ", error)
				setAskToConfirmEmail(false)
			},
		})
	}

	useEffect(() => {
		if (!isSignInWithEmailLink(firebaseAuth, window.location.href)) {
			logger.info("Not a sign-in with email link")
			return
		}

		const email = window.localStorage.getItem(LOCAL_STORAGE_EMAIL_KEY)
		if (!email) {
			logger.error("No email found in local storage")
			setAskToConfirmEmail(true)
			return
		}

		completeSignIn({ email })
	}, [])

	return { completeSignIn, askToConfirmEmail, setAskToConfirmEmail, res }
}
