import TextInputSection from "@/components/InputSelection/SettingsInputSelection"
import type { ConfigSettings } from "@coderabbitai/schemas"
import type { WidgetProps } from "@rjsf/utils"
import type { FC } from "react"
import type { FieldPath } from "react-hook-form"
import { useConfigCtx } from "../../context"
import { changeToTitleCase, getNiceNameAndDescription } from "../utils"

export const TextWidget: FC<
	WidgetProps & { display?: "flex" | "grid" }
> = props => {
	const { checkIfRepoSettings } = useConfigCtx()

	const { niceDescription, niceName } = getNiceNameAndDescription(
		props.schema.description,
	)

	return (
		<TextInputSection
			id={props.id}
			label={niceName || changeToTitleCase(props.label.replace(/_/g, " "))}
			name={props.name as FieldPath<ConfigSettings>}
			value={props.value}
			disabled={checkIfRepoSettings()}
			onChange={e => {
				props.onChange(e.target.value)
			}}
			text={niceDescription || ""}
		/>
	)
}
