interface PrimaryButtonProps {
	readonly title: string
	readonly disabled?: boolean
	readonly onClick?: () => void
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
	title,
	disabled,
	onClick,
}) => {
	return (
		<button
			type="button"
			className="font-interSemibold mb-2 rounded-xl bg-crb-primary px-6 py-3 text-sm font-normal text-white transition focus:outline-none focus:ring-4 focus:ring-[#ff8e6252] enabled:hover:bg-crb-primary-dark disabled:bg-gray-200 disabled:text-slate-400 dark:bg-crb-primary dark:focus:ring-[#ff8e6252] dark:enabled:hover:bg-crb-primary-dark"
			disabled={disabled}
			onClick={onClick}
		>
			{title}
		</button>
	)
}

export default PrimaryButton
