import { firebaseAuth, SESSION_STORAGE_TENANT_ID } from "@/firebase"
import { logger } from "@/lib/utils"

export async function logout(): Promise<void> {
	const isFirebase = !!sessionStorage.getItem(SESSION_STORAGE_TENANT_ID)
	window.ko?.reset()
	sessionStorage.clear()
	window.localStorage.clear()

	window.localStorage.setItem("last_route", window.location.pathname)
	logger.info("logging out....")
	if (isFirebase) {
		await firebaseAuth.signOut().catch(() => {
			logger.error("Failed to sign out firebase, redirecting to login")
		})

		logger.info("Signed out firebase")
		window.location.assign("/sign-in-with-email")

		return
	}

	window.location.assign("/login")
}
