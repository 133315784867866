import Loader from "@/components/Loader/Loader"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { trpc } from "@/trpc"
import React, { useEffect } from "react"
import { FaCheckCircle, FaInfoCircle } from "react-icons/fa"
import { toast } from "react-toastify"

interface TriggerReportPreviewModal {
	readonly open: boolean
	readonly onOpenChange: (open: boolean) => void
	readonly reportID: string
}

const TriggerReportPreview: React.FC<TriggerReportPreviewModal> = ({
	open,
	onOpenChange,
	reportID,
}) => {
	const [previewTriggered, setPreviewTriggered] = React.useState<boolean>(false)

	const triggerReportPreview = trpc.reporting.triggerReportPreview.useMutation({
		onSuccess: () => {
			toast.success(`Report preview triggered successfully`)
			setPreviewTriggered(true)
		},
	})

	useEffect(() => {
		if (triggerReportPreview.error) {
			toast.error(
				"Failed to trigger report preview: " +
					triggerReportPreview.error.message,
			)
		}
	}, [triggerReportPreview.error])

	useEffect(() => {
		if (open) {
			setPreviewTriggered(false)
		}
	}, [open])

	const isLoading = triggerReportPreview.isLoading

	return (
		<Dialog open={open} onOpenChange={onOpenChange}>
			<DialogContent
				onPointerDownOutside={e => {
					e.preventDefault()
				}}
				className="sm:max-w-md"
			>
				{isLoading && <Loader />}

				<DialogHeader>
					<DialogTitle>Report preview</DialogTitle>
				</DialogHeader>

				{!previewTriggered && (
					<div>
						Are you sure you want to generate a preview of the report?
						<div className="mt-2 flex gap-1 text-sm text-gray-500">
							<FaInfoCircle className="mr-0.5 mt-1 flex-shrink-0 text-gray-400" />
							The preview will be created based on the settings saved currently
						</div>
					</div>
				)}

				{previewTriggered && (
					<div>
						<FaCheckCircle className="mb-0.5 mr-1.5 inline-block text-xl text-green-500" />
						The report preview has been triggered successfully and will be sent
						to the selected channel shortly.
						<div className="mt-2 flex gap-1 text-sm text-gray-500">
							<FaInfoCircle className="mr-0.5 mt-1 flex-shrink-0 text-gray-400" />
							The preview may take a minute or two to generate
						</div>
					</div>
				)}

				<DialogFooter className="sm:justify-end">
					<DialogClose asChild>
						<Button type="button" variant="secondary">
							{previewTriggered ? "OK" : "Cancel"}
						</Button>
					</DialogClose>

					{!previewTriggered && (
						<Button
							type="submit"
							onClick={() => {
								triggerReportPreview.mutate(reportID)
							}}
						>
							Generate Preview
						</Button>
					)}
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default TriggerReportPreview
