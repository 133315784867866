import { Button } from "@/components/ui/button"
import { Switch } from "@/components/ui/switch.js"
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip"
import { trpc, type RouterInputs } from "@/trpc"
import type {
	GHHandlerGetJiraRefreshTokenResp,
	GHHandlerGetLinearRefreshTokenResp,
} from "@/typings/githubActionsHandler.ts"
import { handleSessionExpiration } from "@/utils/session"
import {
	getSelectedOrg,
	isTRPCClientError,
	sentryCaptureException,
} from "@/utils/utils.ts"
import { useEffect, useState } from "react"
import { CgSpinner } from "react-icons/cg"
import { FaExclamationTriangle } from "react-icons/fa"
import { useNavigate } from "react-router"
import { toast } from "react-toastify"
import jira from "../../assets/jira.svg"
import linear from "../../assets/linear.svg"
import Loader from "../../components/Loader/Loader"
import JiraSelfHostedModal from "./JiraSelfHostedModal"

export default function Integrations() {
	const [isJiraAuthorized, setIsJiraAuthorized] = useState<boolean>(false)
	const [isJiraIntegrationSaved, setIsJiraIntegrationSaved] =
		useState<boolean>(false)
	const [jiraSelfHostedURL, setJiraSelfHostedURL] = useState<string>()
	const [isJiraInvalidAuth, setIsJiraInvalidAuth] = useState<boolean>(false)
	const [isLinearAuthorized, setIsLinearAuthorized] = useState<boolean>(false)
	const [isLinearIntegrationSaved, setIsLinearIntegrationSaved] =
		useState<boolean>(false)
	const [isLinearInvalidAuth, setIsLinearInvalidAuth] = useState<boolean>(false)

	const JIRA = "jira"
	const LINEAR = "linear"

	const navigate = useNavigate()
	const trpcUtils = trpc.useUtils()

	const [loader, setLoader] = useState<boolean>(false)
	const [loaderMessage, setLoaderMessage] = useState<string>("")

	const [openSelfHostedModal, setOpenSelfHostedModal] = useState<boolean>(false)

	useEffect(() => {
		function fetchData() {
			setLoader(true)
			const urlParams = new URLSearchParams(window.location.search)
			const urlState = urlParams.get("state")
			const service = sessionStorage.getItem("service")
			if (service && urlState) {
				setLoader(true)
				const authCode = urlParams.get("code")
				const storedState = sessionStorage.getItem("state")
				if (storedState != urlState) {
					console.error("CSRF attack detected! The states do not match.")
					return
				}
				if (authCode) {
					const setIsAuthorized =
						service === "jira" ? setIsJiraAuthorized : setIsLinearAuthorized
					const setIsIntegrationSaved =
						service === "jira"
							? setIsJiraIntegrationSaved
							: setIsLinearIntegrationSaved

					void handleAuthorization(
						authCode,
						setIsAuthorized,
						setIsIntegrationSaved,
						service,
					)
				}
			}
			void fetchIntegrations()
		}
		fetchData()
	}, [])

	async function fetchIntegrations() {
		try {
			setLoaderMessage("Fetching Integrations... 🚀")
			if (!getSelectedOrg()?.id) {
				throw new Error("Organization ID not found.")
			}
			const response =
				await trpcUtils.client.integrations.getIntegrations.query()

			if (response.isSuccess) {
				const integrations = response.data
				if (integrations.length > 0) {
					integrations.forEach(integration => {
						if (integration.service === JIRA.toUpperCase()) {
							setIsJiraAuthorized(true)
							setIsJiraIntegrationSaved(true)
							if (integration.isSelfHosted && integration.host_url) {
								setJiraSelfHostedURL(integration.host_url)
							}
							setIsJiraInvalidAuth(integration.isRefreshTokenInvalid)
						} else if (integration.service === LINEAR.toUpperCase()) {
							setIsLinearAuthorized(true)
							setIsLinearIntegrationSaved(true)
							setIsLinearInvalidAuth(integration.isRefreshTokenInvalid)
						}
					})
				}
				setLoader(false)
			}
		} catch (error) {
			if (isTRPCClientError(error)) {
				if (error.data?.code === "UNAUTHORIZED") {
					handleSessionExpiration(navigate)
					return
				}
				if (error.data?.code === "BAD_REQUEST") {
					toast.error(
						"Failed to fetch integrations. Please try logging back in.",
					)
					sentryCaptureException(
						"Integrations: fetchIntegrations API failed: ",
						error.message,
					)
					setLoader(false)
					return
				}
			}
			setLoader(false)
			if (error instanceof Error) {
				if (error.message === "Organization ID not found.") {
					toast.error(
						"Failed to fetch integrations. Please try logging out and back in.",
					)
					return
				}
			}
			sentryCaptureException("Integrations: Organization ID not found. ", error)
		}
	}

	/**
	 * Saves the integration data.
	 * @param data - The integration data.
	 * @param setIsAuthorized - The function to set authorization status.
	 * @param setIsIntegrationSaved - The function to set integration saved status.
	 */
	async function saveIntegration(
		data:
			| GHHandlerGetJiraRefreshTokenResp
			| GHHandlerGetLinearRefreshTokenResp
			| RouterInputs["integrations"]["saveIntegration"],
		setIsAuthorized: typeof setIsJiraAuthorized,
		setIsIntegrationSaved: typeof setIsJiraIntegrationSaved,
	) {
		setLoader(true)
		setLoaderMessage("Saving Integration... 🚀")

		const saveIntegrationData: RouterInputs["integrations"]["saveIntegration"] =
			"pat" in data
				? /* Jira Self-Hosted */
					{
						service: "Jira",
						pat: data.pat,
						host_url: data.host_url,
					}
				: /* Jira/Linear Cloud */
					{
						service: data.service,
						service_id: data.service_id || "",
						host_url: data.host_url || "",
						access_token_encrypted: data.access_token_encrypted,
						access_token_tag: data.access_token_tag,
						access_token_iv: data.access_token_iv,
						access_token_validity: data.access_token_validity,
						refresh_token_encrypted: data.refresh_token_encrypted || "",
						refresh_token_tag: data.refresh_token_tag || "",
						refresh_token_iv: data.refresh_token_iv || "",
					}

		await trpcUtils.client.integrations.saveIntegration
			.mutate(saveIntegrationData)
			.then(() => {
				setIsAuthorized(true)
				setIsIntegrationSaved(true)
				if ("pat" in data) {
					setJiraSelfHostedURL(data.host_url)
				}
				setIsJiraInvalidAuth(false)
				setIsLinearInvalidAuth(false)
				setOpenSelfHostedModal(false)
				sessionStorage.removeItem("state")
				sessionStorage.removeItem("service")
			})
			.catch(error => {
				sentryCaptureException(
					"Integrations: saveIntegration API failed: ",
					error,
				)
				toast.error("Failed to save integration: " + error)
			})
			.finally(() => {
				setLoader(false)
			})
	}

	async function handleAuthorization(
		authCode: string,
		setIsAuthorized: typeof setIsJiraAuthorized,
		setIsIntegrationSaved: typeof setIsJiraIntegrationSaved,
		service: string,
	) {
		if (service === JIRA) {
			await trpcUtils.client.integrations.getJiraRefreshToken
				.query(authCode)
				.then(async response => {
					if (response.isSuccess) {
						const data = response.data

						await saveIntegration(data, setIsAuthorized, setIsIntegrationSaved)
						const newUrl = `${window.location.origin}/integrations`
						window.history.replaceState(null, "", newUrl)
					} else {
						toast.error(
							"Failed to connect Jira integration. Please try again later.",
						)
					}
				})
				.catch(error => {
					sentryCaptureException(
						"Integrations: JiraRefreshToken API failed: ",
						error,
					)
					toast.error(
						"Failed to connect Jira integration. Please try again later.",
					)
					if (error.response?.status === 401) {
						handleSessionExpiration(navigate)
					}
				})
		} else if (service === LINEAR) {
			await trpcUtils.client.integrations.getLinearAccessToken
				.query(authCode)
				.then(async response => {
					if (response.isSuccess) {
						const data = response.data

						await saveIntegration(data, setIsAuthorized, setIsIntegrationSaved)
						const newUrl = `${window.location.origin}/integrations`
						window.history.replaceState(null, "", newUrl)
					} else {
						toast.error(
							"Failed to connect Linear integration. Please try again later.",
						)
					}
				})
				.catch(error => {
					sentryCaptureException(
						"Integrations: LinearAccessToken API failed: ",
						error,
					)
					toast.error(
						"Failed to connect Linear integration. Please try again later.",
					)
				})
		}
	}

	// Jira
	const handleJiraConnect = () => {
		const JIRA_AUTH_URL = import.meta.env.VITE_JIRA_AUTH_URL
		const JIRA_CLIENT_ID = import.meta.env.VITE_JIRA_CLIENT_ID
		const JIRA_REDIRECT_URI = import.meta.env.VITE_JIRA_REDIRECT_URI

		const jiraAuthUrl = new URL(JIRA_AUTH_URL)

		const state = generateNonce(10)
		sessionStorage.setItem("state", state)
		sessionStorage.setItem("service", JIRA)

		const appendUrlParams = (params: Record<string, string>) => {
			Object.entries(params).forEach(([key, value]) => {
				jiraAuthUrl.searchParams.append(key, value)
			})
		}
		appendUrlParams({
			audience: "api.atlassian.com",
			client_id: JIRA_CLIENT_ID,
			scope:
				"read:jira-work manage:jira-webhook write:jira-work offline_access",
			redirect_uri: JIRA_REDIRECT_URI,
			state: state,
			response_type: "code",
			prompt: "consent",
		})

		window.location.href = jiraAuthUrl.toString()
	}

	async function handleJiraDisconnect() {
		try {
			const ownerId = sessionStorage.getItem("org_id")
			if (!ownerId) {
				toast.error(
					"An Error occurred removing the Jira integration, please try again later.",
				)
				return
			}
			await trpcUtils.client.integrations.disconnectIntegration
				.mutate({
					service: JIRA,
				})
				.then(response => {
					if (response.isSuccess) {
						setIsJiraAuthorized(false)
						setIsJiraIntegrationSaved(false)
						setJiraSelfHostedURL(undefined)
						setIsJiraInvalidAuth(false)
						toast.success("Jira integration disconnected successfully.")
					} else throw new Error("Failed to disconnect Jira integration")
				})
		} catch (error) {
			sentryCaptureException(
				"Integrations: disconnectJiraIntegration API failed: ",
				error,
			)
			toast.error(
				"An Error occurred removing the Jira integration, please try again later.",
			)
		}
	}

	// Linear
	const handleLinearConnect = () => {
		const LINEAR_AUTH_URL = import.meta.env.VITE_LINEAR_AUTH_URL
		const LINEAR_CLIENT_ID = import.meta.env.VITE_LINEAR_CLIENT_ID
		const LINEAR_REDIRECT_URI = import.meta.env.VITE_LINEAR_REDIRECT_URI

		const linearAuthUrl = new URL(LINEAR_AUTH_URL)

		const appendUrlParams = (params: Record<string, string>) => {
			Object.entries(params).forEach(([key, value]) => {
				linearAuthUrl.searchParams.append(key, value)
			})
		}

		const state = generateNonce(10)
		sessionStorage.setItem("state", state)
		sessionStorage.setItem("service", LINEAR)

		appendUrlParams({
			client_id: LINEAR_CLIENT_ID,
			redirect_uri: LINEAR_REDIRECT_URI,
			response_type: "code",
			scope: "read",
			state: state,
			prompt: "consent",
			actor: "user",
		})

		window.location.href = linearAuthUrl.toString()
	}

	async function handleLinearDisconnect() {
		try {
			const ownerId = sessionStorage.getItem("org_id")
			if (!ownerId) {
				toast.error(
					"An Error occurred removing the linear integration, please try again later.",
				)
				return
			}
			await trpcUtils.client.integrations.disconnectIntegration
				.mutate({
					service: LINEAR,
				})
				.then(res => {
					if (res.isSuccess) {
						setIsLinearAuthorized(false)
						setIsLinearIntegrationSaved(false)
						setIsLinearInvalidAuth(false)
						toast.success("Linear integration disconnected successfully.")
					} else throw new Error("Failed to disconnect Linear integration")
				})
				.catch(error => {
					toast.error(
						"An Error occurred removing the linear integration, please try again later.",
					)
					sentryCaptureException(
						"Integrations: disconnectLinearIntegration API failed: ",
						error,
					)
				})
		} catch (error) {
			sentryCaptureException(
				"Integrations: disconnectLinearIntegration API failed: ",
				error,
			)
		}
	}

	function generateNonce(length: number): string {
		const characters =
			"abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
		const randomBytes = new Uint8Array(length)
		window.crypto.getRandomValues(randomBytes)

		let result = ""
		for (const byte of randomBytes) {
			const randomIndex = byte % characters.length
			result += characters.charAt(randomIndex)
		}

		return result
	}

	const integrations = [
		{
			name: "Jira",
			description: "Plan, track, and release great software.",
			logo: jira,
			authorized: isJiraAuthorized,
			integrationSaved: isJiraIntegrationSaved,
			showLoader: isJiraAuthorized && !isJiraIntegrationSaved,
			connect: handleJiraConnect,
			disconnect: handleJiraDisconnect,
			invalidAuth: isJiraInvalidAuth,
		},
		{
			name: "Linear",
			description: "Streamline software projects, sprints, and bug tracking.",
			logo: linear,
			authorized: isLinearAuthorized,
			integrationSaved: isLinearIntegrationSaved,
			showLoader: isLinearAuthorized && !isLinearIntegrationSaved,
			connect: handleLinearConnect,
			disconnect: handleLinearDisconnect,
			invalidAuth: isLinearInvalidAuth,
		},
	] as const

	return (
		<>
			{loader ? (
				<Loader size="small" message={loaderMessage} />
			) : (
				<div className="container mx-auto px-8 pb-2 pt-7">
					<div className="mb-6">
						<div className="font-500 mb-2 font-inter text-2xl leading-8 text-foreground">
							Integrations
						</div>
						<div className="font-400 max-w-3xl font-inter text-sm leading-5 text-muted-foreground">
							If you use one of these services, we recommend integrating them
							with CodeRabbit. This will allow CodeRabbit to use the context
							from the linked issues while reviewing the code. New workflow
							integrations are in progress and will be added upon availability.
						</div>
					</div>

					<div className="flex flex-col flex-wrap gap-6 sm:flex-row">
						{integrations.map(integration => (
							<div
								key={integration.name}
								className="flex-1 rounded-lg border p-6 sm:min-w-60 sm:max-w-4xl"
							>
								<div className="flex justify-between">
									<div className="flex flex-wrap items-center gap-4">
										<div className="rounded-lg border p-1.5">
											<img
												src={integration.logo}
												className="w-12 min-w-12"
												alt={`${integration.name} Logo`}
											/>
										</div>
										<div>
											<div className="flex items-center gap-2">
												<h2 className="font-weight-600 text-lg text-crb-text-primary">
													{integration.name}
												</h2>
												{integration.invalidAuth && (
													<Tooltip>
														<TooltipTrigger>
															<FaExclamationTriangle className="text-sm text-yellow-600" />
														</TooltipTrigger>
														<TooltipContent>
															Something went wrong with the authorization.{" "}
															<br /> Please re-authenticate with{" "}
															{integration.name}
														</TooltipContent>
													</Tooltip>
												)}
											</div>
											{integration.name === "Jira" && jiraSelfHostedURL && (
												<div className="text-sm text-muted-foreground">
													{jiraSelfHostedURL}
												</div>
											)}
										</div>
									</div>
									{integration.showLoader ? (
										<CgSpinner className="animate-spin text-xl text-crb-primary-dark" />
									) : (
										<Switch
											checked={integration.authorized}
											onCheckedChange={
												integration.authorized
													? integration.disconnect
													: integration.connect
											}
										/>
									)}
								</div>
								<div className="mt-6 font-poppins text-crb-text-tertiary">
									{integration.description}
								</div>
								{integration.name === "Jira" && !integration.authorized && (
									<>
										<Button
											variant="link"
											className="px-0 text-muted-foreground"
											onClick={() => {
												setOpenSelfHostedModal(true)
											}}
										>
											Using Jira Data Center (Self-Hosted)?
										</Button>
									</>
								)}
							</div>
						))}
					</div>
				</div>
			)}
			<JiraSelfHostedModal
				open={openSelfHostedModal}
				onOpenChange={setOpenSelfHostedModal}
				onSaveCreds={(host_url, pat) => {
					void saveIntegration(
						{ host_url, pat, service: "Jira" },
						setIsJiraAuthorized,
						setIsJiraIntegrationSaved,
					)
				}}
			/>
		</>
	)
}
