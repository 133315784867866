import type { UserData } from "@/typings"
import type { CRHandlerUpdateUserSeatsResp } from "@/typings/coderabbitHandler.ts"
import { getPathConfig } from "../network/ApiConstants"
import APIService from "../network/ApiService"

export interface UpdateSeatsListPayload {
	readonly subscriptionId: number
	readonly userSeats?: UserData[]
	readonly provider: string
	readonly loggedInDetails: {
		readonly org_name: string
		readonly user_id: string | null
		readonly user_name: string | null
	}
	readonly joinImmediate: boolean
}

export default class UpdateSeatsList {
	async invoke(payload: UpdateSeatsListPayload) {
		try {
			const instance = APIService.getInstance()
			const { subscriptionId, userSeats, provider, loggedInDetails } = payload
			const newList = userSeats?.map(user => {
				const { user_name, user_id, on_seat } = user
				return { user_name: user_name, user_id: user_id.toString(), on_seat }
			})

			const newPayload = {
				joinImmediate: payload.joinImmediate,
				userData: newList,
				loggedInDetails,
			}

			if (payload.joinImmediate) {
				delete newPayload.userData
			}

			const result = await instance.put<CRHandlerUpdateUserSeatsResp>(
				`${
					getPathConfig().UPDATE_SEATS_LIST
				}?subscription_id=${subscriptionId.toString()}&provider=${provider}`,
				newPayload,
			)

			return {
				kind: result.status === 200 ? "ok" : "error",
				...result.data,
				status: result.status,
			}
		} catch (err) {
			throw Error(`${err}`)
		}
	}
}
