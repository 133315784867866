import { Button } from "@/components/ui/button"
import type {
	ArrayFieldTemplateItemType,
	ArrayFieldTemplateProps,
} from "@rjsf/utils"
import { FaPlus } from "react-icons/fa"
import { changeToTitleCase, getNiceNameAndDescription } from "../utils"

import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined"
import { Chip, TextField } from "@mui/material"
import { cloneDeep, get, isEqual, update as lodashUpdate, noop } from "lodash"
import {
	useEffect,
	useMemo,
	useRef,
	useState,
	type Dispatch,
	type FC,
	type HTMLAttributes,
	type KeyboardEvent,
	type PropsWithChildren,
	type ReactNode,
	type SetStateAction,
} from "react"
import { useConfigCtx } from "../../context"

interface LabelAndDescriptionProps {
	title: string
	description: ReactNode
	canAdd?: boolean
	onAddClick: ArrayFieldTemplateProps["onAddClick"]
}

export const LabelAndDescription: FC<
	PropsWithChildren<LabelAndDescriptionProps>
> = ({ title, description, children, canAdd, onAddClick }) => {
	const { checkIfRepoSettings } = useConfigCtx()
	return (
		<div className="w-full pb-3 pt-4 sm:pt-3">
			<div className="flex w-full flex-col gap-2" id="ignored_titles">
				<div className="flex items-center gap-4">
					<div className="flex w-full flex-col gap-2">
						<span className="font-500 font-poppins not-italic leading-5 text-[#242424]">
							{title}
						</span>
						<div className="flex items-center gap-2 font-poppins text-sm font-normal not-italic leading-4 text-[#606060]">
							{description}
						</div>
					</div>
					{canAdd && (
						<Button
							type="button"
							size="sm"
							title="Add"
							variant="secondary"
							onClick={onAddClick}
							disabled={checkIfRepoSettings()}
						>
							<FaPlus className="mr-2 inline" size={16} /> {title}
						</Button>
					)}
				</div>
				{children}
			</div>
		</div>
	)
}

export const RemoveFieldsButton: FC<
	Omit<ArrayFieldTemplateItemType, "key"> & {
		onClickDelete?: () => void
		divProps?: HTMLAttributes<HTMLDivElement>
	}
> = ({ onDropIndexClick, index, divProps, onClickDelete = noop }) => {
	return (
		<div className="pt-2" {...divProps}>
			<Button
				variant="outline"
				className="hover:border-error w-22 flex h-fit items-center rounded-lg border-2 p-1 transition-all focus:outline-none"
				type="button"
				aria-label="add"
				size="sm"
				onClick={e => {
					onClickDelete()
					onDropIndexClick(index)(e)
				}}
			>
				<DeleteForeverOutlinedIcon fontSize="medium" />
				<span className="ml-2">Delete</span>
			</Button>
		</div>
	)
}

export function ArrayFieldTemplate(props: ArrayFieldTemplateProps) {
	const { items, canAdd, onAddClick, title, schema, formData, required } = props

	const { setConfig, config, checkIfRepoSettings } = useConfigCtx()

	const isStringArray = useMemo(
		() => (schema.items as { type: string }).type === "string",
		[],
	)

	const label = changeToTitleCase(title.replace(/_/g, " "))

	const initialFormData = useRef(formData)
	const initialConfig = useRef(config)

	const [text, setText] = useState<string[]>(initialFormData.current || [])

	const fieldPath = useMemo(() => {
		if (Array.isArray(formData) && isStringArray && initialConfig.current) {
			return findArrayFieldPath(title, initialConfig.current)
		}

		return ""
	}, [initialConfig.current])

	useEffect(() => {
		if (Array.isArray(formData) && isStringArray && fieldPath) {
			const currentField = get(config, fieldPath)
			if (currentField && isEqual(currentField, text)) {
				return
			}

			const configCopy = cloneDeep(config)

			if (!currentField && !text.length) {
				lodashUpdate(configCopy, fieldPath, () => [])
			} else {
				lodashUpdate(configCopy, fieldPath, () => text)
			}

			setConfig(configCopy)
		}
	}, [text])

	const { niceDescription, niceName } = getNiceNameAndDescription(
		schema.description,
	)

	return (
		<>
			{isStringArray ? (
				<div className="mb-4">
					<ArrayField
						{...{
							label: niceName || label,
							description: niceDescription || "",
							required,
							setValues: setText,
							values: text,
						}}
					/>
				</div>
			) : (
				<LabelAndDescription
					{...{
						title: niceName || label,
						description: niceDescription || "",
						canAdd: !!canAdd,
						onAddClick,
					}}
				>
					{items.map(el => {
						return (
							<div
								className="relative grid w-full grid-cols-1 gap-2 lg:grid-cols-[1fr,auto]"
								key={el.key}
							>
								{el.children}
								{el.hasRemove && !checkIfRepoSettings() && (
									<RemoveFieldsButton {...el} key={el.key} />
								)}
							</div>
						)
					})}
				</LabelAndDescription>
			)}
		</>
	)
}

function findArrayFieldPath<T extends object>(
	key: string,
	config: T,
	prefix = "",
): string | undefined {
	for (const [k, v] of Object.entries(config)) {
		const path = prefix.length ? `${prefix}.${k}` : k

		if (v && typeof v === "object" && !Array.isArray(v)) {
			const foundPath = findArrayFieldPath(key, v, path)
			if (foundPath) {
				return foundPath
			}
		}

		if (k === key) {
			return path
		}
	}
}

interface ArrayFieldProps {
	label: string
	required: boolean | undefined
	setValues: Dispatch<SetStateAction<string[]>>
	values: string[]
	description: ReactNode
}

export const ArrayField: FC<ArrayFieldProps> = ({
	label,
	required,
	description,
	setValues,
	values,
}) => {
	const [inputValue, setInputValue] = useState<string>("")

	const updateValues = () => {
		if (inputValue.trim().length === 0 || values.includes(inputValue.trim())) {
			return
		}
		setValues([...values, inputValue])
		setInputValue("")
	}

	const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			event.preventDefault()
			updateValues()
		}
	}

	const handleDelete = (valueToDelete: string) => {
		setValues(values.filter(value => value !== valueToDelete))
	}

	const { checkIfRepoSettings } = useConfigCtx()

	return (
		<div className="grid gap-4 pb-2 pt-2 sm:flex sm:flex-col lg:grid lg:grid-cols-2">
			<div>
				<div className="font-500 flex gap-3 font-inter not-italic leading-5">
					<div className="flex-1 font-inter">
						{label}
						{required && <span className="text-red-500">*</span>}
						{description && (
							<p className="max-w-3xl pt-1 text-sm font-light not-italic leading-[20px] text-muted-foreground">
								{description}
							</p>
						)}
					</div>
				</div>
			</div>
			<div className="flex w-full sm:mt-1">
				<TextField
					value={inputValue}
					className="flex w-full rounded-md border border-input bg-background px-3 font-inter text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed"
					onChange={event => {
						setInputValue(event.target.value)
					}}
					onKeyDown={handleKeyDown}
					fullWidth
					maxRows={4}
					disabled={checkIfRepoSettings()}
					sx={{
						"& .MuiOutlinedInput-root": {
							fontSize: "14px",
							...(checkIfRepoSettings() && {
								opacity: 0.5,
							}),
							"&.Mui-focused fieldset": {
								borderColor: "black",
								borderRadius: "0.375rem",
							},
						},
					}}
					size="small"
					InputProps={{
						startAdornment: values.map((value, index) => (
							<div key={index} className="mr-0.5 mt-1">
								<Chip
									label={value}
									variant="outlined"
									size="small"
									onDelete={() => {
										handleDelete(value)
									}}
									className="m-1 mb-0.5 mt-0.5 border-gray-200 bg-transparent text-[18px] font-semibold"
								/>
							</div>
						)),
						classes: {
							adornedStart: "flex flex-wrap",
						},
					}}
					onBlur={updateValues}
					helperText={
						<span className="text-sx flex items-center justify-between py-2 font-semibold text-gray-700">
							Press Enter to add a new value
							<span
								className="cursor-pointer rounded-xl border border-gray-900 px-1 py-0 text-xs text-gray-600 hover:bg-gray-300"
								onClick={() => {
									setValues([])
								}}
							>
								Clear
							</span>
						</span>
					}
				/>
			</div>
		</div>
	)
}
