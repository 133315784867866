import { Button } from "@/components/ui/button"
import { FormControl, FormItem } from "@/components/ui/form"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import { cn } from "@/lib/utils"
import type { FC, PropsWithChildren } from "react"
import { useConfigCtx } from "../../context"
import type { SelectInputSectionProps } from "../types"
import { changeToTitleCase, getNiceNameAndDescription } from "../utils"

export const SelectInputWidget: FC<
	PropsWithChildren<SelectInputSectionProps>
> = ({ props, children, selectOptionsElement, texts }) => {
	const { checkIfRepoSettings } = useConfigCtx()
	const { niceDescription, niceName } = getNiceNameAndDescription(
		props.schema.description,
	)
	return (
		<div className="gap-4 pb-2 pt-2 md:grid-cols-1 lg:grid lg:grid-cols-2">
			<div>
				<span className="font-500 font-poppins not-italic leading-5 text-[#242424]">
					{texts?.title ||
						niceName ||
						changeToTitleCase(props.label.replace(/_/g, " "))}
				</span>
				<p className="font-poppins text-sm font-normal not-italic leading-4 text-[#606060]">
					{texts?.description || niceDescription}
				</p>
			</div>
			<div className="mt-2 w-80">
				{!selectOptionsElement &&
				(props.options.enumOptions?.length === 2 ||
					props.options.enumOptions?.length === 3) ? (
					<div className="inline-flex flex-wrap items-center justify-center rounded-md bg-muted p-1 text-muted-foreground hover:text-gray-400 sm:flex-nowrap">
						{props.options.enumOptions.map(({ label, value }, i) => (
							<Button
								type="button"
								variant="ghost"
								className={cn(
									props.value === value ? " bg-white hover:bg-white" : "",
									"h-10 text-black",
								)}
								onClick={() => {
									props.onChange(value)
								}}
								disabled={checkIfRepoSettings()}
								key={i}
							>
								{changeToTitleCase(label)}
							</Button>
						))}
					</div>
				) : (
					<FormItem>
						<Select
							name={props.name}
							onValueChange={props.onChange}
							defaultValue={props.value as string}
							disabled={checkIfRepoSettings()}
						>
							<FormControl>
								<SelectTrigger>
									<SelectValue placeholder={props.placeholder} />
								</SelectTrigger>
							</FormControl>
							<SelectContent>
								{selectOptionsElement ??
									props.options.enumOptions?.map(({ label, value }, i) => {
										return (
											<SelectItem value={value} key={i}>
												{label}
											</SelectItem>
										)
									})}
							</SelectContent>
						</Select>
					</FormItem>
				)}
				{children}
			</div>
		</div>
	)
}
