import type { ScheduleWeekDay } from "../../../typings/githubActionsHandler"

export enum DayMapping {
	Sunday = 0,
	Monday,
	Tuesday,
	Wednesday,
	Thursday,
	Friday,
	Saturday,
}

// Days of the week: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
export const days = Object.values(DayMapping).filter(value =>
	isNaN(Number(value)),
) as string[]

export function convertDaysToNumbers(dayNames: string[]): ScheduleWeekDay[] {
	return dayNames.map(day => DayMapping[day as keyof typeof DayMapping])
}

export function convertDaysToNames(dayNumbers: ScheduleWeekDay[]): string[] {
	return dayNumbers.map(day => days[day])
}
