import type { PgLearning } from "@/typings/coderabbitHandler"
import { camelCase } from "lodash"
import type { ChipsInfo } from "./components/LearningFilter"

type CreateCommentLinkProps = Pick<
	PgLearning,
	"pull_request" | "repo_name" | "repo_owner" | "top_level_comment_id"
>

export function createCommentLink({
	top_level_comment_id,
	pull_request,
	repo_owner,
	repo_name,
}: CreateCommentLinkProps) {
	if (!top_level_comment_id || !pull_request) {
		return null
	}

	return `https://github.com/${repo_owner}/${repo_name}/pull/${pull_request}#discussion_r${top_level_comment_id}`
}

export function createPineconeFilter(chips: ChipsInfo[]) {
	return chips.reduce<Record<string, { $eq: number | string }>>(
		(acc, { key, value }) => {
			const camelCaseKey = camelCase(key)
			acc[camelCaseKey] = { $eq: value }
			return acc
		},
		{},
	)
}
