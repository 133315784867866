import type { ReactNode } from "react"
import { DescriptionLink } from "./DescriptionLink"

export function changeToTitleCase(str: string) {
	return str.replace(
		/\w\S*/g,
		txt => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase(),
	)
}

const MD_LINK_REGEX = /\[([^\]]+)\]\(([^)]+)\)/
const VERSION_REGEX = /^v\d+\.\d+\.\d+$/
export function getNiceNameAndDescription(description = "") {
	const list = description.split("|").map(el => el.trim())

	let niceName = "",
		niceDescription = "",
		version

	list.forEach(item => {
		if (!niceName) {
			niceName = item
		} else if (!niceDescription) {
			niceDescription = item
		} else {
			const match = VERSION_REGEX.exec(item)
			if (match) {
				version = match[0]
			}
		}
	})

	const details = {
		niceName: list.length > 1 ? niceName : null,
		niceDescription: list.length > 1 ? niceDescription : description,
		version: list.length > 1 ? version : null,
	}

	const match = MD_LINK_REGEX.exec(details.niceDescription)

	let descriptionWithLink: ReactNode

	if (match) {
		const name = match[1]
		const link = match[2]

		const split = details.niceDescription.split(match[0])

		descriptionWithLink = (
			<>
				{split[0]}
				<DescriptionLink linkName={name} link={link} />
				{split[1]}
			</>
		)
	}

	return {
		...details,
		niceDescription: descriptionWithLink ?? details.niceDescription,
		version: version ? version : null,
	}
}
