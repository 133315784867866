import ImageButton from "@/components/ImageButton/ImageButton"
import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { logger } from "@/lib/utils"
import { trpc } from "@/trpc"
import { EMAIL_REGEX } from "@/utils/constant"
import { getSelectedOrg } from "@/utils/utils"
import { useState, type FC } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"

interface FormData {
	email: string
	fullName: string
}
interface InviteUserProps {
	readonly disabled: boolean
}
export const InviteUser: FC<InviteUserProps> = ({ disabled }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>()
	const [open, setOpen] = useState(false)
	const { isLoading, mutate: invite } =
		trpc.inviteAdminUserToOrg.invite.useMutation()

	const onSubmit = (data: FormData) => {
		invite(data, {
			onError: error => {
				logger.error("Error inviting user: ", error)
				toast.error(`Error inviting user: ${error.message}`)
			},
			onSuccess: res => {
				if (!res.isSuccess) {
					toast.error(res.message)
					return
				}
				toast.success(`Invite sent to ${data.email}`)
				setOpen(false)
			},
		})
	}

	return (
		<div>
			<ImageButton
				onClick={() => {
					setOpen(true)
				}}
				disabled={disabled}
				title="Invite User"
			/>

			<Dialog
				open={open}
				onOpenChange={open => {
					setOpen(open)
				}}
			>
				<DialogContent>
					<DialogTitle>Invite User</DialogTitle>
					<div className="font-poppins text-sm font-normal not-italic leading-4 text-[#606060]">
						If you want to invite an admin user who is not a part of the
						organization in your git provider (GitHub/GitLab/Azure DevOps), you
						can invite them using their email address. They can sign in on{" "}
						<a
							target="__blank"
							className="font-medium text-crb-primary hover:underline"
							href={`/sign-in-with-email/${getSelectedOrg()?.organization_name}`}
						>
							this page
						</a>
						.
					</div>

					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="flex flex-col gap-2">
							<div>
								<Input
									id="fullName"
									type="text"
									placeholder="Full Name"
									{...register("fullName", {
										required: "Full Name is required",
									})}
								/>
								{errors.fullName && (
									<p className="mt-1 text-sm text-red-500">
										{errors.fullName.message}
									</p>
								)}
							</div>
							<div>
								<Input
									id="email"
									type="email"
									placeholder="Email"
									{...register("email", {
										required: "Email is required",
										pattern: {
											value: EMAIL_REGEX,
											message: "Invalid email address",
										},
									})}
								/>
								{errors.email && (
									<p className="mt-1 text-sm text-red-500">
										{errors.email.message}
									</p>
								)}
							</div>
						</div>
						<Button
							type="submit"
							className="mt-3 w-full text-secondary-foreground text-white"
							disabled={isLoading}
						>
							Send invite
						</Button>
					</form>
				</DialogContent>
			</Dialog>
		</div>
	)
}
