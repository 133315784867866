import type { ConfigSettings } from "@coderabbitai/schemas"
import { isEqual } from "lodash"
import { useEffect, useState, type FC } from "react"

interface SaveConfigMessageProps {
	initialConfig: ConfigSettings | null
	config: ConfigSettings
}

export const SaveConfigMessage: FC<SaveConfigMessageProps> = ({
	config,
	initialConfig,
}) => {
	const [showMessage, setShowMessage] = useState(false)

	useEffect(() => {
		if (!initialConfig) {
			return
		}

		if (!isEqual(config, initialConfig) && !showMessage) {
			setTimeout(() => {
				setShowMessage(true)
			}, 1000)
			return
		}

		if (isEqual(config, initialConfig)) {
			setTimeout(() => {
				setShowMessage(false)
			}, 1000)
		}
	}, [config, initialConfig])

	return (
		<>
			{showMessage && (
				<div
					className="border-l-4 border-yellow-500 bg-yellow-100 px-4 py-2 font-poppins text-yellow-700"
					role="alert"
				>
					<p className="text-sm">
						You have unsaved changes. Please apply the changes to save them.
					</p>
				</div>
			)}
		</>
	)
}
