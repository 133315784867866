import React, { useCallback } from "react"
import { LuPanelLeftClose, LuPanelLeftOpen } from "react-icons/lu"
import "../Referral/GrowSurf.css"
import { Button } from "../ui/button"

interface NavSidebarProps {
	isCollapsed: boolean
	setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>
	contents: React.ReactNode
}

const NavSidebar: React.FC<NavSidebarProps> = ({
	isCollapsed,
	setIsCollapsed,
	contents,
}) => {
	const toggleCollapse = useCallback(() => {
		setIsCollapsed(prev => !prev)
	}, [setIsCollapsed])

	return (
		<div
			className={`fixed left-0 top-0 z-20 flex h-screen flex-shrink-0 flex-col overflow-y-auto border-r-[1px] shadow-lg md:relative md:overflow-visible
				${isCollapsed ? "hidden md:flex md:w-20" : "w-4/5"} bg-white text-black transition-all duration-300 ease-in-out md:max-w-xs`}
		>
			<div
				className={
					!isCollapsed
						? "fixed right-0 h-screen w-1/5 bg-[#0C111D] bg-opacity-85 md:hidden"
						: ""
				}
				onClick={toggleCollapse}
			/>

			{contents}

			<div className="mx-3">
				<Button
					variant="outline"
					className="mb-4 h-fit w-full justify-start gap-2 px-3 py-2"
					onClick={toggleCollapse}
				>
					{isCollapsed ? (
						<LuPanelLeftOpen size={20} className="flex-1" />
					) : (
						<LuPanelLeftClose size={20} />
					)}
					{!isCollapsed && "Collapse"}
				</Button>
			</div>
		</div>
	)
}

export default NavSidebar
