import ButtonRegular from "@/components/ButtonRegular/ButtonRegular"
import { trpc } from "@/trpc"
import { useProvider } from "@/utils/providers"
import type { TRPCError } from "@trpc/server"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { toast } from "react-toastify"
import type { GetEmploymentRolesOutput } from "../../../../coderabbitHandler/src/utils/zod_schemas/hubspot"
import { companyRolesList } from "./company-roles"

const Profile: React.FC = () => {
	const navigate = useNavigate()
	const trpcUtils = trpc.useUtils()
	const { provider } = useProvider()

	const subscriber_id = sessionStorage.getItem("subscriber_id")
	const user_id = sessionStorage.getItem("user_id")
	const host_url = sessionStorage.getItem("host_url")

	const [profileData, setProfileData] = useState({
		email: "",
		firstName: "",
		lastName: "",
		companyEmail: "",
		companyRole: "",
		companyName: "",
	})

	const [companyRoles, setCompanyRoles] = useState<GetEmploymentRolesOutput>([])

	const { mutate: setUserProfile, isLoading } =
		trpc.users.setUserProfile.useMutation()

	useEffect(() => {
		if (!subscriber_id) {
			toast.error("Missing user information. Please log in again.")
			navigate("/login")
			return
		}
		getCompanyRoles()
		void getProfile()
	}, [subscriber_id, user_id, host_url])

	const getProfile = async () => {
		if (!user_id) return
		try {
			const res = await trpcUtils.users.getUser.fetch({
				provider_user_id: user_id,
				provider: provider ?? "",
				host_url: host_url ?? "",
			})
			setProfileData(prevData => ({
				...prevData,
				email: res.data.email ?? prevData.email,
				firstName: res.data.first_name ?? prevData.firstName,
				lastName: res.data.last_name || prevData.lastName,
				companyName: res.data.company_name || prevData.companyName,
				companyEmail: res.data.company_email || prevData.companyEmail,
				companyRole: res.data.company_role || prevData.companyRole,
			}))
		} catch (err: unknown) {
			const error = err as TRPCError
			if (error.code === "NOT_FOUND") {
				toast.error("User profile not found")
				navigate("/login")
				return
			}
			toast.error(`Failed to fetch subscriber details: ${error.message || ""}`)
		}
	}

	const handleSaveChanges = () => {
		if (!user_id) return

		// Email validation for company email
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		if (
			profileData.companyEmail &&
			!emailRegex.test(profileData.companyEmail)
		) {
			toast.error("Please enter a valid company email")
			return
		}

		setUserProfile(
			{
				first_name: profileData.firstName,
				last_name: profileData.lastName,
				company_name: profileData.companyName,
				company_role: profileData.companyRole,
				company_email: profileData.companyEmail,
			},
			{
				onSuccess: () => toast.success("Profile updated successfully"),
				onError: error =>
					toast.error(`Failed to update profile: ${error.message || ""}`),
			},
		)
	}

	const getCompanyRoles = () => {
		try {
			setCompanyRoles(companyRolesList)
		} catch (error) {
			console.error("Failed to fetch company roles:", error)
			toast.error("Failed to load company roles. Please try again later.")
		}
	}

	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
	) => {
		const { name, value } = e.target
		setProfileData(prev => ({ ...prev, [name]: value }))
	}

	return (
		<>
			<div className="container mx-auto px-4 py-6">
				<div className="mb-6 flex items-center justify-between">
					<h1 className="text-2xl font-semibold text-foreground">Profile</h1>
					<ButtonRegular
						title="Save"
						onClick={handleSaveChanges}
						disabled={isLoading}
					/>
				</div>
				<div className="rounded-lg border p-6">
					<h2 className="mb-4 text-lg font-semibold text-foreground">
						Personal Details
					</h2>
					<div className="space-y-4">
						<InputField
							label="Primary Email"
							name="email"
							value={profileData.email}
							readOnly
						/>
						<div className="flex flex-col space-y-2 sm:flex-row sm:items-center">
							<label className="w-full text-sm font-medium sm:w-1/3">
								Name
							</label>
							<div className="flex w-full flex-col space-y-2 sm:w-2/3 sm:flex-row sm:space-x-4 sm:space-y-0">
								<input
									name="firstName"
									aria-label="First name"
									value={profileData.firstName}
									onChange={handleInputChange}
									placeholder="First name"
									className="w-full rounded-lg border p-2 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-black sm:w-1/2"
								/>
								<input
									name="lastName"
									aria-label="Last name"
									value={profileData.lastName}
									onChange={handleInputChange}
									placeholder="Last name"
									className="w-full rounded-lg border p-2 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-black sm:w-1/2"
								/>
							</div>
						</div>
					</div>

					<hr className="my-6" />

					<h2 className="mb-4 text-lg font-semibold text-foreground">
						Work Details
					</h2>
					<div className="space-y-4">
						<InputField
							label="Company Name"
							name="companyName"
							value={profileData.companyName}
							onChange={handleInputChange}
							placeholder="Acme Inc."
						/>
						<div className="flex flex-col space-y-2 sm:flex-row sm:items-center">
							<label className="w-full text-sm font-medium sm:w-1/3">
								Work Role
							</label>
							<select
								name="companyRole"
								aria-label="Select your work role"
								value={profileData.companyRole}
								onChange={handleInputChange}
								className="w-full rounded-lg border p-2 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-black sm:w-2/3"
							>
								<option disabled selected value="">
									-- Select your work role --
								</option>
								{companyRoles.map(role => (
									<option
										key={role.value}
										value={role.value}
										hidden={role.hidden}
									>
										{role.label}
									</option>
								))}
							</select>
						</div>
						<InputField
							label="Work Email"
							name="companyEmail"
							value={profileData.companyEmail}
							onChange={handleInputChange}
							placeholder="email@acme.com"
						/>
					</div>
				</div>
			</div>
		</>
	)
}

const InputField: React.FC<{
	label?: string
	name: string
	value: string
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
	placeholder?: string
	readOnly?: boolean
	className?: string
}> = ({ label, name, value, onChange, placeholder, readOnly, className }) => (
	<div className="flex flex-col space-y-2 sm:flex-row sm:items-center">
		{label && (
			<label htmlFor={name} className="w-full text-sm font-medium sm:w-1/3">
				{label}
			</label>
		)}
		<input
			id={name}
			name={name}
			value={value}
			onChange={onChange}
			placeholder={placeholder}
			readOnly={readOnly}
			className={`w-full rounded-lg border p-2 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-black sm:w-2/3 ${className}`}
		/>
	</div>
)

export default Profile
