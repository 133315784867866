export const companyRolesList = [
	{
		hidden: false,
		label: "Architect IT",
		value: "architect_it",
	},
	{
		hidden: false,
		label: "Business Analyst",
		value: "business_analyst",
	},
	{
		hidden: false,
		label: "Business Consultant",
		value: "business_consultant",
	},
	{
		hidden: false,
		label: "Business Manager",
		value: "business_manager",
	},
	{
		hidden: false,
		label: "Chief Financial Officer",
		value: "chief_financial_officer",
	},
	{
		hidden: false,
		label: "Communications Manager",
		value: "communications_manager",
	},
	{
		hidden: false,
		label: "Community",
		value: "community",
	},
	{
		hidden: false,
		label: "Content Marketing",
		value: "content_marketing",
	},
	{
		hidden: false,
		label: "Contracts",
		value: "contracts",
	},
	{
		hidden: false,
		label: "Customer Service Specialist",
		value: "customer_service_specialist",
	},
	{
		hidden: false,
		label: "Customer Success",
		value: "customer_success",
	},
	{
		hidden: false,
		label: "Data IT",
		value: "data_it",
	},
	{
		hidden: false,
		label: "Data Science Engineer",
		value: "data_science_engineer",
	},
	{
		hidden: false,
		label: "Database Administrator",
		value: "database_administrator",
	},
	{
		hidden: false,
		label: "Design Engineer",
		value: "design_engineer",
	},
	{
		hidden: false,
		label: "Development Specialist",
		value: "development_specialist",
	},
	{
		hidden: false,
		label: "Devops Engineer",
		value: "devops_engineer",
	},
	{
		hidden: false,
		label: "Digital Marketing",
		value: "digital_marketing",
	},
	{
		hidden: false,
		label: "Director of Development",
		value: "director_of_development",
	},
	{
		hidden: false,
		label: "Electrical Engineer",
		value: "electrical_engineer",
	},
	{
		hidden: false,
		label: "Engineering Manager",
		value: "engineering_manager",
	},
	{
		hidden: false,
		label: "Executive Assistant",
		value: "executive_assistant",
	},
	{
		hidden: false,
		label: "Financial Analyst",
		value: "financial_analyst",
	},
	{
		hidden: false,
		label: "Financial Controller",
		value: "financial_controller",
	},
	{
		hidden: false,
		label: "Founder",
		value: "founder",
	},
	{
		hidden: false,
		label: "General Manager",
		value: "general_manager",
	},
	{
		hidden: false,
		label: "General Partner",
		value: "general_partner",
	},
	{
		hidden: false,
		label: "Graphic Design",
		value: "graphic_design",
	},
	{
		hidden: false,
		label: "Information Technology Specialist",
		value: "information_technology_specialist",
	},
	{
		hidden: false,
		label: "Investment",
		value: "investment",
	},
	{
		hidden: false,
		label: "Investment Banker",
		value: "investment_banker",
	},
	{
		hidden: false,
		label: "Key Account Manager",
		value: "key_account_manager",
	},
	{
		hidden: false,
		label: "Management",
		value: "management",
	},
	{
		hidden: false,
		label: "Mechanical Engineer",
		value: "mechanical_engineer",
	},
	{
		hidden: false,
		label: "Network Engineer",
		value: "network_engineer",
	},
	{
		hidden: false,
		label: "Office Management",
		value: "office_management",
	},
	{
		hidden: false,
		label: "Office Manager",
		value: "office_manager",
	},
	{
		hidden: false,
		label: "Operational Specialist",
		value: "operational_specialist",
	},
	{ hidden: false, label: "Owner", value: "owner" },
	{
		hidden: false,
		label: "Principal",
		value: "principal",
	},
	{
		hidden: false,
		label: "Product Design",
		value: "product_design",
	},
	{
		hidden: false,
		label: "Product Manager",
		value: "product_manager",
	},
	{
		hidden: false,
		label: "Product Marketing",
		value: "product_marketing",
	},
	{
		hidden: false,
		label: "Professor",
		value: "professor",
	},
	{
		hidden: false,
		label: "Program Coordinator",
		value: "program_coordinator",
	},
	{
		hidden: false,
		label: "Program Manager",
		value: "program_manager",
	},
	{
		hidden: false,
		label: "Project Engineer",
		value: "project_engineer",
	},
	{
		hidden: false,
		label: "Project Manager",
		value: "project_manager",
	},
	{
		hidden: false,
		label: "QA engineer",
		value: "qa_engineer",
	},
	{ hidden: false, label: "QA IT", value: "qa_it" },
	{
		hidden: false,
		label: "Quality Assurance Manager",
		value: "quality_assurance_manager",
	},
	{
		hidden: false,
		label: "Quality Assurance Specialist",
		value: "quality_assurance_specialist",
	},
	{
		hidden: false,
		label: "Relationship Manager",
		value: "relationship_manager",
	},
	{
		hidden: false,
		label: "Research Analyst",
		value: "research_analyst",
	},
	{
		hidden: false,
		label: "Risk Compliance",
		value: "risk_compliance",
	},
	{
		hidden: false,
		label: "Sales Executive",
		value: "sales_executive",
	},
	{
		hidden: false,
		label: "Sales Operations",
		value: "sales_operations",
	},
	{
		hidden: false,
		label: "Sales Specialist",
		value: "sales_specialist",
	},
	{
		hidden: false,
		label: "Social Marketing",
		value: "social_marketing",
	},
	{
		hidden: false,
		label: "Software Engineer",
		value: "software_engineer",
	},
	{
		hidden: false,
		label: "Strategy",
		value: "strategy",
	},
	{
		hidden: false,
		label: "Student",
		value: "student",
	},
	{
		hidden: false,
		label: "Support",
		value: "support",
	},
	{
		hidden: false,
		label: "Support Specialist",
		value: "support_specialist",
	},
	{
		hidden: false,
		label: "System Administrator",
		value: "system_administrator",
	},
	{
		hidden: false,
		label: "System Analyst",
		value: "system_analyst",
	},
	{
		hidden: false,
		label: "Systems Engineer",
		value: "systems_engineer",
	},
	{
		hidden: false,
		label: "Teacher",
		value: "teacher",
	},
	{
		hidden: false,
		label: "Technical Manager",
		value: "technical_manager",
	},
	{
		hidden: false,
		label: "Technical Support Specialist",
		value: "technical_support_specialist",
	},
	{
		hidden: false,
		label: "Web Developer",
		value: "web_developer",
	},
	{
		hidden: false,
		label: "Writer",
		value: "writer",
	},
	{
		hidden: false,
		label: "Chief Executive Officer",
		value: "chief_executive_officer",
	},
	{
		hidden: false,
		label: "Chief Operating Officer",
		value: "chief_operating_officer",
	},
	{
		hidden: false,
		label: "Chief Marketing Officer",
		value: "chief_marketing_officer",
	},
	{
		hidden: false,
		label: "Chief Technology Officer",
		value: "chief_technology_officer",
	},
	{
		hidden: false,
		label: "Chief Information Officer",
		value: "chief_information_officer",
	},
	{
		hidden: false,
		label: "Chief Human Resources officer",
		value: "chief_human_resources_officer",
	},
	{
		hidden: false,
		label: "Chief Compliance Officer",
		value: "chief_compliance_officer",
	},
	{
		hidden: false,
		label: "Chief Risk Officer",
		value: "chief_risk_officer",
	},
	{
		hidden: false,
		label: "Chief Data Officer",
		value: "chief_data_officer",
	},
	{
		hidden: false,
		label: "Chief Product Officer",
		value: "chief_product_officer",
	},
	{
		hidden: false,
		label: "Chief Revenue Officer",
		value: "chief_revenue_officer",
	},
	{
		hidden: false,
		label: "Chief Sustainability Officer",
		value: "chief_sustainability_officer",
	},
	{
		hidden: false,
		label: "Chief Legal Officer",
		value: "chief_legal_officer",
	},
	{
		hidden: false,
		label: "Chief Security Officer",
		value: "chief_security_officer",
	},
	{
		hidden: false,
		label: "Chief Experience Officer",
		value: "chief_experience_officer",
	},
	{
		hidden: false,
		label: "Chief Innovation Officer",
		value: "chief_innovation_officer",
	},
	{
		hidden: false,
		label: "Other",
		value: "other",
	},
]
