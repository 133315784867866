export const getPathConfig = () => {
	const codeRabbitHandler = import.meta.env.VITE_CODERABBIT_FUNC_URL
	// const billingHandler = import.meta.env.VITE_BILLING_FUNC_URL

	return {
		UPDATE_SEATS_LIST: `${codeRabbitHandler}/updateSeats`,
	}
}

export const getAPIConfig = () => {
	return {
		headers: {
			common: {
				"Content-Type": "application/json",
				Accept: "application/json",
				"Cache-Control": "no-cache",
			},
		},
	}
}
