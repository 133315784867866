import { Button } from "@/components/ui/button"
import { Form } from "@/components/ui/form"
import { Textarea } from "@/components/ui/textarea"
import { trpc } from "@/trpc"
import type { PgLearning } from "@/typings/coderabbitHandler"
import { Stack } from "@mui/material"
import moment from "moment"
import type { Dispatch, FC, SetStateAction } from "react"
import { useForm, type SubmitHandler } from "react-hook-form"
import { toast } from "react-toastify"
import { MetadataInfo, RepoContext } from "../components/HelperComponents"
import { createCommentLink } from "../utils"

interface EditLearningProps {
	learning: PgLearning
	setShowEditLearning: Dispatch<SetStateAction<boolean>>
}

export const EditLearning: FC<EditLearningProps> = ({
	learning,
	setShowEditLearning,
}) => {
	const { mutate: updateLearning, isLoading } =
		trpc.learnings.updateLearning.useMutation()
	const form = useForm<Pick<PgLearning, "learning">>({
		defaultValues: {
			learning: learning.learning,
		},
	})

	const utils = trpc.useUtils()

	const submitHandler: SubmitHandler<Pick<PgLearning, "learning">> = ({
		learning: l,
	}) => {
		updateLearning(
			{ id: learning.id, learning: l },
			{
				onSuccess: async () => {
					toast.success("Learning updated successfully")
					// refetch learnings
					await utils.learnings.getAllLearnings.invalidate().catch(e => {
						console.error(e)
					})
					form.reset()
					setShowEditLearning(false)
				},
				onError: () => {
					toast.error("Failed to update learning")
				},
			},
		)
	}

	const commentLink = createCommentLink({
		top_level_comment_id: learning.top_level_comment_id,
		pull_request: learning.pull_request,
		repo_owner: learning.repo_owner,
		repo_name: learning.repo_name,
	})

	return (
		<Form {...form}>
			<div className="flex w-full flex-col gap-3 font-poppins">
				<p className="pb-3 text-lg font-semibold">Edit learning</p>
				<div className="flex flex-col gap-6 rounded bg-gray-100 p-4">
					<RepoContext
						info={[
							{
								title: "Repository",
								value: learning.repo_name,
								divProps: {
									className: "text-sm",
								},
							},
							...(learning.file
								? [
										{
											title: "File",
											value: learning.file,
											divProps: {
												className: "text-crb-primary text-sm",
											},
										},
									]
								: []),
							...(learning.pull_request
								? [
										{
											title: "Pull Request",
											value: commentLink ? (
												<a
													href={commentLink}
													target="__blank"
													className="font-medium text-crb-primary underline"
												>
													{learning.pull_request}
												</a>
											) : (
												learning.pull_request
											),
											divProps: {
												className: "text-crb-primary text-sm",
											},
										},
									]
								: []),
							...(learning.url
								? [
										{
											title: "URL",
											value: learning.url,
											divProps: {
												className: "text-crb-primary text-sm",
											},
										},
									]
								: []),
						]}
					/>
				</div>
				<div className="grid grid-cols-2 grid-rows-2 gap-y-[12px]">
					<MetadataInfo
						info={[
							{
								title: "Created on",
								value: moment(learning.createdAt).format(
									"MMMM Do YYYY, h:mm:ss a",
								),
							},
							{
								title: "Updated on",
								value: moment(learning.updatedAt).format(
									"MMMM Do YYYY, h:mm:ss a",
								),
							},
						]}
					/>
					<MetadataInfo
						info={[
							{
								title: "Created by",
								value: learning.user,
							},
						]}
					/>
				</div>
				<div>
					<Stack
						component="form"
						className="flex w-full flex-col gap-3"
						onSubmit={form.handleSubmit(submitHandler)}
					>
						<p className="pb-2 text-[14px] font-semibold">Learning</p>
						<Textarea
							{...form.register("learning", {
								required: "Learning is required",
							})}
							placeholder="Learning"
							rows={4}
						/>

						<div className="mt-3 flex items-center justify-end gap-3">
							<Button
								type="button"
								variant="outline"
								disabled={isLoading}
								color="error"
								onClick={() => {
									setShowEditLearning(false)
								}}
							>
								Cancel
							</Button>
							<Button type="submit" disabled={isLoading}>
								Save
							</Button>
						</div>
					</Stack>
				</div>
			</div>
		</Form>
	)
}
