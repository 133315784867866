import { useProvider } from "@/utils/providers"
import React from "react"
import { FaArrowRight } from "react-icons/fa"
import { LuChevronsUpDown } from "react-icons/lu"
import { getGitHubOauthUrl } from "../../utils/utils"
import { Button } from "../ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover"
import { Skeleton } from "../ui/skeleton"
import DesktopSelectOrg from "./DesktopSelectOrg"
import { useSelectedOrg } from "./useSelectedOrg"

interface DesktopOrgMenuProps {
	readonly isCollapsed?: boolean
	readonly popoverSide?: "bottom" | "left" | "right" | "top"
}

const DesktopOrgMenu: React.FC<DesktopOrgMenuProps> = ({
	isCollapsed,
	popoverSide = "right",
}) => {
	const selectedOrg = useSelectedOrg()

	const { getProviderIcon, isGitHubCloud } = useProvider()

	return (
		<Popover>
			<PopoverTrigger className="w-full" asChild>
				<Button variant="ghost" className="h-fit text-start">
					{getProviderIcon()}
					{!isCollapsed && (
						<>
							<div className="flex flex-grow items-center overflow-hidden">
								{" "}
								<div className="ml-3 min-w-0 flex-1">
									{selectedOrg && (
										<div className="font-500 truncate text-lg text-foreground">
											{selectedOrg.organization_name}
										</div>
									)}
									{!selectedOrg && (
										<Skeleton className="mb-2 h-6 w-full rounded-md" />
									)}
									<div className="-mt-1 text-sm text-muted-foreground">
										Change Organization
									</div>
								</div>
								<LuChevronsUpDown className="ml-4 flex-shrink-0" size={20} />
							</div>
						</>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent
				side={popoverSide}
				align="start"
				className="z-20 p-0"
				sideOffset={20}
			>
				<DesktopSelectOrg selectedOrgName={selectedOrg?.organization_name} />
				{isGitHubCloud && (
					<div className="block border-t px-4 py-2">
						<h4 className="text-sm">Can't find an organization?</h4>
						<a
							className="group mt-1 flex items-center gap-1 text-xs text-muted-foreground"
							target="_blank"
							href={getGitHubOauthUrl()}
							rel="noreferrer"
						>
							Check Permissions
							<FaArrowRight
								className="transition-all group-hover:ml-0.5"
								size={10}
							/>
						</a>
					</div>
				)}
			</PopoverContent>
		</Popover>
	)
}

export default DesktopOrgMenu
