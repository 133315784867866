import Loader from "@/components/Loader/Loader"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { trpc } from "@/trpc"
import React from "react"
import { toast } from "react-toastify"

export interface ProEligibilityData {
	isEligible: boolean

	data: {
		noOfSeats: number
		totalCharge: number
	}
}

interface ActivateProModalProps {
	open: boolean
	onOpenChange: (open: boolean) => void
	onActivatePro?: () => void
	isCheckingEligibility: boolean
	proEligibilityData: ProEligibilityData
	planName?: string
}

const ActivateProModal: React.FC<ActivateProModalProps> = ({
	open,
	onOpenChange,
	onActivatePro,
	isCheckingEligibility,
	proEligibilityData,
	planName,
}) => {
	const activateProMutation = trpc.subscriptions.activatePro.useMutation({
		onSuccess: ({ isSuccess: response }) => {
			if (response) {
				onActivatePro?.()
				toast.success("Pro Activated Successfully")
			}
		},
		onError: () => {
			toast.error("Failed to activate Pro... Please Contact Support.")
		},
	})

	const handleSubmit = () => {
		activateProMutation.mutate()
	}

	const isLoading = activateProMutation.isLoading

	return (
		<Dialog open={open} onOpenChange={onOpenChange}>
			<DialogContent
				onPointerDownOutside={e => {
					e.preventDefault()
				}}
				className="min-h-[200px] bg-white sm:max-w-md "
			>
				{(isLoading || isCheckingEligibility) && (
					<div className="absolute inset-0 z-50 flex items-center justify-center bg-white/80">
						<Loader />
					</div>
				)}
				{!isCheckingEligibility && proEligibilityData && (
					<>
						<DialogHeader>
							<DialogTitle>Activate Pro</DialogTitle>
							<DialogDescriptionContent data={proEligibilityData} />
						</DialogHeader>

						<DialogFooter>
							{proEligibilityData.isEligible ? (
								<Button
									className="px-4 py-2 font-inter font-semibold"
									type="button"
									onClick={handleSubmit}
								>
									Activate {planName}
								</Button>
							) : (
								""
							)}
							<DialogClose asChild>
								<Button type="button" variant="secondary">
									Close
								</Button>
							</DialogClose>
						</DialogFooter>
					</>
				)}
			</DialogContent>
		</Dialog>
	)
}

export default ActivateProModal

const DialogDescriptionContent: React.FC<{ data: ProEligibilityData }> = ({
	data,
}) => {
	if (!data.isEligible) {
		return (
			<DialogDescription className="py-4 font-normal">
				You must have payment-details to activate the pro.
			</DialogDescription>
		)
	}

	const { noOfSeats, totalCharge } = data.data
	const seatsText = noOfSeats > 1 ? "seats" : "seat"

	return (
		<DialogDescription className="py-4 font-normal">
			The subscription will be activated for {noOfSeats} number of {seatsText}{" "}
			and you will be charged ${totalCharge}
		</DialogDescription>
	)
}
