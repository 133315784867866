import { Chip } from "@mui/material"
import type { FC, HTMLAttributes, ReactNode } from "react"

export const MetadataInfo: FC<{
	info: { title: string; value: string }[]
}> = ({ info }) => (
	<>
		{info.map(({ title, value }, i) => (
			<div className="flex flex-col gap-1" key={i}>
				<div className="mt-3 text-sm">{title}</div>
				<div>
					<Chip
						label={value}
						variant="outlined"
						size="small"
						className="border-gray-200 bg-transparent text-[14px]"
					/>
				</div>
			</div>
		))}
	</>
)

export const RepoContext: FC<{
	info: {
		title: string
		value: ReactNode
		divProps?: HTMLAttributes<HTMLDivElement>
	}[]
}> = ({ info }) => (
	<>
		{info.map(({ title, value, divProps }, i) => (
			<div className="md:grid md:grid-cols-[1fr_2fr] md:gap-4" key={i}>
				<div className="text-sm text-gray-600">{title}</div>
				<div {...divProps}>{value}</div>
			</div>
		))}
	</>
)
