import { isEmpty, noop } from "lodash"
import {
	addGlobalUncaughtErrorHandler,
	loadMicroApp,
	start,
	type FrameworkLifeCycles,
	type LifeCycleFn,
	type LoadableApp,
	type MicroApp,
} from "qiankun"
import type { ReactNode } from "react"

import { logger } from "@/lib/utils"
import { GRAFANA_APP_NAME, VITE_GRAFANA_API_URL } from "./consts"

export type FailedToMountGrafanaErrorName =
	(typeof GrafanaUtils)["FAILED_TO_MOUNT_GRAFANA_ERROR_NAME"]

export interface GrafanaProps {
	name: string
	uid: string
	/**
	 * NOTE:
	 * If undefined then the latest will be used
	 */
	version: number | undefined
	slug: string
	queryParams: Record<string, string> | undefined
	fnError: ReactNode | undefined
	pageTitle: string | undefined
	controlsContainer: string | null | undefined
	isLoading: (isLoading: boolean) => void
	setErrors: (errors: Record<number | string, string>) => void
	hiddenVariables: string[] | undefined
	container: HTMLElement | undefined
	mode: "dark" | "light" | undefined
}

export type LoadAppOptions = Pick<
	GrafanaProps,
	| "container"
	| "controlsContainer"
	| "fnError"
	| "hiddenVariables"
	| "isLoading"
	| "name"
	| "pageTitle"
	| "queryParams"
	| "setErrors"
	| "slug"
	| "uid"
	| "version"
> & {
	entry: string | undefined
}

export type MutableGrafanaProps = Partial<
	Pick<
		GrafanaProps,
		"hiddenVariables" | "queryParams" | "slug" | "uid" | "version"
	>
>

export type EnhancedLifeCycles = {
	[K in keyof FrameworkLifeCycles<GrafanaProps>]?: LifeCycleFn<GrafanaProps>
}

export const SHOULD_LOG_GRAFANA = false

export interface MicroAppData {
	props: LoadableApp<Partial<GrafanaProps>> & {
		container: HTMLElement | string | null
	}
	app: MicroApp
}

export class GrafanaUtils {
	static readonly FAILED_TO_MOUNT_GRAFANA_ERROR_NAME = "FailedToMountGrafana"

	static readonly DEFAULT_ENTRY = `${VITE_GRAFANA_API_URL}/public/microfrontends/fn_dashboard/`

	static readonly VIEW_PORT_ID = "grafana-provider-viewport"

	static readonly ERROR_MESSAGE =
		"Error Loading Dashboards. Try refreshing page."

	readonly log = logger

	private readonly onUncaughtError: OnErrorEventHandlerNonNull

	appData: MicroAppData | null = null

	constructor(onUncaughtError: OnErrorEventHandlerNonNull = noop) {
		this.onUncaughtError = err => {
			if (
				err instanceof Error &&
				err.name === GrafanaUtils.FAILED_TO_MOUNT_GRAFANA_ERROR_NAME
			) {
				this.log.error("Failed to mount grafana app.", err)

				return
			}

			onUncaughtError(err)
		}

		addGlobalUncaughtErrorHandler(this.onUncaughtError)
	}

	update = async (options: MutableGrafanaProps) => {
		if (isEmpty(options)) {
			this.log.error("Failed to update app")

			return
		}

		const app = this.appData?.app

		if (!app) {
			this.log.error("Failed to update app")

			return
		}

		await app.update?.(options)

		this.log.info("updated grafana app")
	}

	load = (
		opt: LoadableApp<Partial<GrafanaProps>> & {
			container: HTMLElement | string
			configuration?: Parameters<typeof loadMicroApp>[1]
		},
	) => {
		const { configuration = {}, ...options } = opt
		const container = options.container || queryViewport()

		const controlsContainer = options.props?.controlsContainer

		this.log.info("Trying to load app", "...")

		const microApp = loadMicroApp<Partial<GrafanaProps>>(
			{
				...options,
				container: container ?? "#grafana-viewport",
				name: options.name || GRAFANA_APP_NAME,
				props: {
					...options.props,
					controlsContainer,
					mode: "light",
					pageTitle: window.document.title,
				},
			},
			{
				...configuration,
				autoStart: false,
			},
		)

		start({
			fetch: window.fetch,
		})

		this.appData = {
			props: options,
			app: microApp,
		}

		this.log.info("MicroApp", "has been loaded:")
	}

	unload = async () => {
		this.log.info("Unloading app")

		await this.appData?.app.unmount()

		this.log.info("Unloaded app")
	}

	setAppData = (data: MicroAppData) => {
		this.appData = data
	}
}

function queryViewport(selector = `#${GrafanaUtils.VIEW_PORT_ID}`) {
	return document.querySelector<HTMLElement>(selector)
}
