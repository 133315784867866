import type { ErrorObject } from "ajv"
import type { FC } from "react"

interface SchemaErrorProps {
	errors: ErrorObject[]
}

export const SchemaErrors: FC<SchemaErrorProps> = ({ errors }) => {
	return (
		<div className="space-y-4">
			{errors.map((error, index) => (
				<div
					key={index}
					className="w-full border-l-4 border-red-500 bg-red-100 p-4 text-red-700"
					role="alert"
				>
					<p className="font-bold">Error</p>
					{error.instancePath && (
						<p className="text-sm">
							Instance Path:{" "}
							<span className="font-bold">{error.instancePath}</span>
						</p>
					)}
					{error.message && <p className="text-sm">Message: {error.message}</p>}
				</div>
			))}
		</div>
	)
}
