import { Button } from "@/components/ui/button"
import { trpc } from "@/trpc"
import { sentryCaptureException } from "@/utils/utils.ts"
import React, { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Loader from "../../../../components/Loader/Loader"
import LogoFull from "../../../../svg/logo-full"

const DiscordAppInstall: React.FC = () => {
	const navigate = useNavigate()
	const { search } = useLocation()

	const [hasError, setHasError] = React.useState<boolean>(false)

	const saveDiscord = trpc.discord.saveDiscordConnection.useMutation()

	useEffect(() => {
		const params = new URLSearchParams(search)
		const guild_id = params.get("guild_id")
		if (!guild_id) {
			setHasError(true)
			return
		}

		saveDiscord.mutate(
			{
				guild_id: guild_id,
			},
			{
				onSuccess: () => {
					navigate("/reports/recurring/new")
				},
				onError: error => {
					setHasError(true)
					toast.error("Failed to save discord connection: " + error.message)
					sentryCaptureException(
						"Failed to save discord connection",
						error.message,
					)
				},
			},
		)
	}, [])

	if (hasError) {
		return (
			<div className="flex h-screen w-screen flex-col items-center justify-center ">
				<LogoFull width={350} />
				<h1 className="mt-4 font-poppins text-lg text-[#242424] sm:text-xl">
					Failed to connect to Discord. Please try again.
				</h1>
				<Button
					onClick={() => {
						navigate("/reports/recurring")
					}}
					className="mt-12 font-poppins text-sm sm:text-base"
				>
					Back to Reporting
				</Button>
			</div>
		)
	}

	return <Loader message="Connecting Discord..." />
}

export default DiscordAppInstall
