import React from "react"

type LogoFullProps = React.SVGProps<SVGSVGElement>

const LogoFull: React.FC<LogoFullProps> = props => {
	return (
		<svg
			width={props.width || 526}
			height="82"
			viewBox="0 0 526 82"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_11_170)">
				<path
					d="M131.902 34.4623C133.94 30.7761 136.782 27.893 140.427 25.8337C144.072 23.7743 148.212 22.7446 152.825 22.7446C158.488 22.7446 163.327 24.248 167.364 27.234C171.4 30.2201 174.077 34.2976 175.436 39.4666H162.668C161.721 37.469 160.362 35.9657 158.632 34.9154C156.882 33.8651 154.905 33.3503 152.68 33.3503C149.097 33.3503 146.214 34.5859 143.99 37.0777C141.766 39.5695 140.654 42.9057 140.654 47.0656C140.654 51.2255 141.766 54.5616 143.99 57.0535C146.214 59.5453 149.097 60.7809 152.68 60.7809C154.905 60.7809 156.882 60.266 158.632 59.2158C160.382 58.1655 161.721 56.6622 162.668 54.6646H175.436C174.077 59.8336 171.379 63.8905 167.364 66.856C163.327 69.8214 158.488 71.3042 152.825 71.3042C148.212 71.3042 144.072 70.2745 140.427 68.2151C136.782 66.1558 133.94 63.2933 131.902 59.6276C129.863 55.962 128.854 51.7815 128.854 47.0656C128.854 42.3497 129.863 38.1486 131.902 34.4623Z"
					fill="#171717"
				/>
				<path
					d="M188.451 69.1422C185.486 67.5565 183.159 65.2912 181.449 62.3463C179.761 59.4015 178.896 55.9623 178.896 52.029C178.896 48.0956 179.761 44.6977 181.47 41.7322C183.2 38.7667 185.547 36.5014 188.534 34.9157C191.52 33.33 194.876 32.5475 198.583 32.5475C202.29 32.5475 205.647 33.33 208.633 34.9157C211.619 36.5014 213.967 38.7667 215.696 41.7322C217.426 44.6977 218.271 48.1368 218.271 52.029C218.271 55.9211 217.406 59.3603 215.655 62.3257C213.905 65.2912 211.537 67.5565 208.53 69.1422C205.523 70.7279 202.166 71.5104 198.439 71.5104C194.712 71.5104 191.396 70.7279 188.431 69.1422H188.451ZM204.143 59.0308C205.709 57.4039 206.491 55.0768 206.491 52.029C206.491 48.9811 205.729 46.6746 204.226 45.0272C202.702 43.4003 200.848 42.5765 198.624 42.5765C196.4 42.5765 194.485 43.3797 192.982 44.986C191.478 46.5923 190.737 48.9399 190.737 52.0084C190.737 55.0768 191.478 57.3833 192.941 59.0102C194.403 60.6371 196.256 61.4608 198.48 61.4608C200.704 61.4608 202.599 60.6371 204.143 59.0102V59.0308Z"
					fill="#171717"
				/>
				<path
					d="M223.007 41.6906C224.469 38.7457 226.487 36.4805 229.02 34.8948C231.553 33.3091 234.374 32.5265 237.505 32.5265C239.996 32.5265 242.262 33.0413 244.321 34.0916C246.38 35.1419 248.007 36.5422 249.181 38.2927V20.7058H260.796V70.954H249.181V65.5173C248.09 67.3296 246.545 68.7711 244.527 69.8626C242.509 70.954 240.161 71.4895 237.505 71.4895C234.374 71.4895 231.553 70.6863 229.02 69.08C226.487 67.4737 224.49 65.1878 223.007 62.2224C221.545 59.2569 220.803 55.8384 220.803 51.9256C220.803 48.0128 221.545 44.6149 223.007 41.67V41.6906ZM246.772 45.1503C245.165 43.4823 243.209 42.6379 240.903 42.6379C238.596 42.6379 236.64 43.4617 235.033 45.1091C233.427 46.7566 232.624 49.0425 232.624 51.9256C232.624 54.8087 233.427 57.1152 235.033 58.8244C236.64 60.5131 238.596 61.378 240.903 61.378C243.209 61.378 245.165 60.5337 246.772 58.8656C248.378 57.1975 249.181 54.9117 249.181 52.008C249.181 49.1043 248.378 46.8184 246.772 45.1503Z"
					fill="#171717"
				/>
				<path
					d="M303.384 54.8087H277.106C277.292 57.1564 278.054 58.9686 279.372 60.2042C280.71 61.4398 282.358 62.0782 284.294 62.0782C287.197 62.0782 289.215 60.8632 290.348 58.4126H302.704C302.066 60.9044 300.933 63.1491 299.265 65.1261C297.618 67.1237 295.538 68.6888 293.046 69.8214C290.554 70.9541 287.774 71.5101 284.685 71.5101C280.978 71.5101 277.662 70.7275 274.779 69.1418C271.876 67.5561 269.61 65.2908 267.983 62.346C266.357 59.4011 265.533 55.962 265.533 52.0286C265.533 48.0952 266.336 44.6561 267.942 41.7112C269.549 38.7664 271.793 36.5011 274.697 34.9154C277.601 33.3297 280.916 32.5471 284.685 32.5471C288.453 32.5471 291.604 33.3091 294.467 34.8536C297.309 36.3981 299.553 38.581 301.16 41.4435C302.766 44.2854 303.569 47.6216 303.569 51.4314C303.569 52.5228 303.507 53.6555 303.363 54.8293L303.384 54.8087ZM291.707 48.363C291.707 46.3654 291.028 44.7797 289.668 43.6058C288.309 42.432 286.621 41.8348 284.582 41.8348C282.543 41.8348 280.999 42.3908 279.66 43.5235C278.321 44.6561 277.498 46.2624 277.189 48.3424H291.728L291.707 48.363Z"
					fill="#171717"
				/>
				<path
					d="M333.327 70.9747L323.421 52.9759H320.641V70.9747H309.026V23.3007H328.508C332.277 23.3007 335.469 23.9597 338.125 25.2776C340.782 26.5956 342.759 28.3873 344.077 30.6731C345.395 32.959 346.054 35.5126 346.054 38.3133C346.054 41.4847 345.168 44.3061 343.376 46.7979C341.585 49.2897 338.949 51.0607 335.469 52.0904L346.465 70.9747H333.347H333.327ZM320.621 44.7591H327.828C329.949 44.7591 331.556 44.2443 332.606 43.194C333.677 42.1437 334.212 40.6816 334.212 38.787C334.212 36.8924 333.677 35.5538 332.606 34.5035C331.535 33.4533 329.949 32.9384 327.828 32.9384H320.621V44.7591Z"
					fill="#171717"
				/>
				<path
					d="M351.841 41.6908C353.303 38.7459 355.321 36.4806 357.854 34.8949C360.387 33.3092 363.229 32.7532 366.338 32.7532H389.63V70.9542H378.015V65.5999C376.882 67.3709 375.317 68.7919 373.299 69.8833C371.281 70.9748 368.933 71.5102 366.277 71.5102C363.188 71.5102 360.387 70.7071 357.854 69.1008C355.321 67.4945 353.323 65.2086 351.841 62.2431C350.378 59.2776 349.637 55.8591 349.637 51.9463C349.637 48.0336 350.378 44.6356 351.841 41.6908ZM375.606 45.1505C373.999 43.4824 372.043 42.6381 369.736 42.6381C367.43 42.6381 365.474 43.4618 363.867 45.1093C362.261 46.7568 361.458 49.0427 361.458 51.9258C361.458 54.8088 362.261 57.1153 363.867 58.8246C365.474 60.5133 367.43 61.3782 369.736 61.3782C372.043 61.3782 373.999 60.5338 375.606 58.8658C377.212 57.1977 378.015 54.9118 378.015 52.0081C378.015 49.1044 377.212 46.8186 375.606 45.1505Z"
					fill="#171717"
				/>
				<path
					d="M413.003 34.1534C415.042 33.0619 417.369 32.5265 420.005 32.5265C423.135 32.5265 425.957 33.3091 428.49 34.8948C431.023 36.4805 433.02 38.7457 434.503 41.6906C435.965 44.6355 436.706 48.054 436.706 51.9462C436.706 55.8384 435.965 59.2775 434.503 62.243C433.041 65.2084 431.023 67.4943 428.49 69.1006C425.957 70.7069 423.135 71.5101 420.005 71.5101C417.328 71.5101 415.001 70.9746 413.003 69.9244C411.006 68.8535 409.441 67.4531 408.308 65.6821V70.9746H396.693V20.7058H408.308V38.4368C409.399 36.6658 410.965 35.2448 413.003 34.1534ZM422.476 45.1297C420.87 43.4823 418.893 42.6585 416.525 42.6585C414.157 42.6585 412.262 43.5028 410.656 45.1709C409.049 46.839 408.246 49.1249 408.246 52.0286C408.246 54.9323 409.049 57.2181 410.656 58.8862C412.262 60.5543 414.218 61.3986 416.525 61.3986C418.831 61.3986 420.808 60.5543 422.435 58.845C424.062 57.1564 424.886 54.8499 424.886 51.9462C424.886 49.0425 424.083 46.7772 422.476 45.1297Z"
					fill="#171717"
				/>
				<path
					d="M457.754 34.1536C459.792 33.0622 462.119 32.5267 464.755 32.5267C467.886 32.5267 470.707 33.3093 473.24 34.895C475.773 36.4807 477.771 38.746 479.253 41.6909C480.715 44.6357 481.457 48.0543 481.457 51.9464C481.457 55.8386 480.715 59.2777 479.253 62.2432C477.791 65.2087 475.773 67.4946 473.24 69.1009C470.707 70.7072 467.886 71.5103 464.755 71.5103C462.078 71.5103 459.751 70.9749 457.754 69.9246C455.756 68.8537 454.191 67.4534 453.058 65.6823V70.9749H441.444V20.7061H453.058V38.4371C454.15 36.666 455.715 35.2451 457.754 34.1536ZM467.227 45.13C465.62 43.4825 463.643 42.6588 461.275 42.6588C458.907 42.6588 457.012 43.5031 455.406 45.1712C453.8 46.8392 452.997 49.1251 452.997 52.0288C452.997 54.9325 453.8 57.2184 455.406 58.8865C457.012 60.5545 458.969 61.3989 461.275 61.3989C463.582 61.3989 465.559 60.5545 467.185 58.8453C468.812 57.1566 469.636 54.8501 469.636 51.9464C469.636 49.0428 468.833 46.7775 467.227 45.13Z"
					fill="#171717"
				/>
				<path
					d="M487.058 27.337C485.76 26.1426 485.122 24.6598 485.122 22.8888C485.122 21.1177 485.76 19.5732 487.058 18.3788C488.355 17.1844 490.002 16.5872 492.041 16.5872C494.08 16.5872 495.666 17.1844 496.963 18.3788C498.26 19.5732 498.899 21.0765 498.899 22.8888C498.899 24.701 498.26 26.1426 496.963 27.337C495.666 28.5314 494.039 29.1286 492.041 29.1286C490.044 29.1286 488.334 28.5314 487.058 27.337ZM497.828 33.0826V70.9747H486.213V33.062H497.828V33.0826Z"
					fill="#171717"
				/>
				<path
					d="M526.001 61.1105V70.9542H520.09C515.889 70.9542 512.594 69.9245 510.246 67.8652C507.899 65.8058 506.725 62.4491 506.725 57.7743V42.6999H502.112V33.0621H506.725V23.8362H518.34V33.0621H525.939V42.6999H518.34V57.9185C518.34 59.0511 518.607 59.8749 519.163 60.3691C519.699 60.8634 520.605 61.1105 521.882 61.1105H526.021H526.001Z"
					fill="#171717"
				/>
				<path
					d="M60.751 81.8688C56.2616 80.386 51.9164 70.254 46.5209 70.254C54.0787 74.8052 50.8661 80.8391 50.2483 81.8688H60.7716H60.751Z"
					fill="#FF570A"
				/>
				<path
					d="M93.4535 45.1502C92.5062 21.0558 73.0247 1.40957 48.9508 0.297524C22.1175 -0.917495 0 20.4586 0 47.0037C0 57.1357 3.21259 66.5057 8.69047 74.1459C11.8413 78.553 16.7631 81.3331 22.0969 81.7861C14.2507 72.8897 20.923 65.4555 20.923 65.4555C17.8134 66.9588 16.5366 72.0248 16.5366 72.0248C10.6469 68.4003 12.006 64.0757 12.006 64.0757C12.3767 63.1696 13.221 60.6778 17.7516 61.5839C26.5245 32.6912 55.4172 47.745 55.4172 47.745C54.9641 46.8389 53.4608 41.6905 53.4608 41.6905C34.7001 38.9722 28.3573 25.6688 28.3573 25.6688C45.7588 20.3762 56.3439 34.7505 56.3439 34.7505C51.5044 22.9504 39.8691 27.6457 36.8418 8.28782C57.0441 11.6446 59.9684 27.481 60.3802 30.8995C60.6685 30.2405 62.2748 29.355 68.906 29.7463C79.1821 30.3435 87.3578 40.7844 87.3578 40.7844C87.3578 61.9546 61.4923 56.8474 61.4923 72.8485C61.4923 76.5966 65.5698 76.37 66.929 78.1823C68.041 79.665 67.5674 81.3125 67.3409 81.8479H69.7915C75.5989 81.8479 81.1179 79.1708 84.5365 74.4754C90.488 66.2998 93.8653 56.1266 93.4535 45.1296V45.1502Z"
					fill="#FF570A"
				/>
			</g>
			<defs>
				<clipPath id="clip0_11_170">
					<rect
						width="526"
						height="81.6122"
						fill="white"
						transform="translate(0 0.256348)"
					/>
				</clipPath>
			</defs>
		</svg>
	)
}

export default LogoFull
