import type { NavItems, SidebarMenuItem } from "./Nav"

export function isNotHidden(
	item: NonNullable<SidebarMenuItem["items"]>[number] | SidebarMenuItem,
): boolean {
	if (typeof item.hide === "function") {
		return !item.hide()
	}

	return !item.hide
}

export function checkIsActive(
	item: (typeof NavItems)[number],
	pathname: string,
) {
	if (typeof item.link === "string" && pathname.startsWith(item.link)) {
		return true
	}

	for (const altItem of item.alt ?? []) {
		if (pathname.startsWith(altItem.link)) {
			return true
		}
	}

	for (const subItem of item.items ?? []) {
		if (pathname.startsWith(subItem.link)) {
			return true
		}
	}

	return false
}
