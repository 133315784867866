import Loader from "@/components/Loader/Loader"
import { Input } from "@/components/ui/input"
import { SignInWithEmailLinkForm } from "@/firebase"
import LogoFull from "@/svg/logo-full"
import { trpc } from "@/trpc"
import { useEffect, useState, type FC } from "react"
import { useParams } from "react-router"

/**
 * This login method is used to signIn customers doesn't have any git provider.
 * Serves at /sign-in-with-email/:orgName
 */
export const SignInWithEmailLink: FC = () => {
	const { orgName: org } = useParams<{ orgName: string }>()
	const [orgName, setOrgName] = useState<string | null>(null)
	const tenantQuery = trpc.findTenantId.find.useQuery(orgName || "", {
		enabled: !!orgName,
	})

	useEffect(() => {
		if (!org) {
			return
		}

		setOrgName(org)
	}, [org])

	if (tenantQuery.isLoading && orgName) {
		return <Loader message="Setting up login with link" />
	}

	return (
		<div className="flex h-screen flex-col items-center justify-center bg-secondary">
			<div className="mx-4 flex w-full max-w-xl flex-col items-center rounded-md bg-white text-center shadow-lg sm:mx-auto">
				<div className="px-4 py-16">
					<div className="flex items-center justify-center pb-5">
						<LogoFull width={171} />
					</div>

					<div className="mb-4 mt-2">
						<div className="font-500 mb-2 font-poppins text-2xl leading-8 text-foreground">
							Welcome to CodeRabbit
						</div>
					</div>

					<div className="font-400 font-poppins text-base text-foreground">
						<SignInWithEmailLinkForm tenantId={tenantQuery.data ?? ""}>
							{tenantQuery.data ? null : (
								<div className="mb-3">
									<Input
										id="org_name"
										type="text"
										placeholder="Organization Name"
										defaultValue={orgName || ""}
										onBlur={e => {
											setOrgName(e.target.value)
										}}
										required
									/>
									{tenantQuery.isError && orgName?.length ? (
										<p className="mt-1 text-sm text-red-500">
											Organization is not found
										</p>
									) : null}
								</div>
							)}
						</SignInWithEmailLinkForm>
					</div>
				</div>
			</div>
		</div>
	)
}
