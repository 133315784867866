import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@/components/ui/tabs.tsx"
import { ENABLED_GRAFANA } from "@/grafana"
import { OverviewDashboard, ToolsDashboard } from "./Dashboard"
import { DashboardPageWrapper, LegacyDashboard } from "./LegacyDashboard"

const tabList = [
	{
		title: "Overview",
		id: "overview",
	},
	{
		title: "Tools",
		id: "tools",
	},
] as const

export default function Dashboard() {
	return (
		<>
			{ENABLED_GRAFANA ? (
				<DashboardPageWrapper>
					<Tabs defaultValue="overview" className="w-full">
						<div className="flex w-full items-center justify-between">
							<TabsList className="mb-3">
								{tabList.map(tab => (
									<TabsTrigger key={tab.id} value={tab.id}>
										{tab.title}
									</TabsTrigger>
								))}
							</TabsList>
						</div>
						<TabsContent value="overview" className="m-[-12px] w-full">
							<OverviewDashboard />
						</TabsContent>
						<TabsContent value="tools" className="m-[-12px] w-full">
							<ToolsDashboard />
						</TabsContent>
					</Tabs>
				</DashboardPageWrapper>
			) : (
				<LegacyDashboard />
			)}
		</>
	)
}
