type RabbitSVGProps = React.SVGProps<SVGSVGElement>

const RabbitSVG: React.FC<RabbitSVGProps> = props => {
	return (
		<svg
			viewBox="0 0 903 784"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M902.854 451.593C902.854 582.796 846.86 701.41 757.072 783.909H648.729C651.049 773.307 645.416 766.018 638.127 761.048C622.886 750.445 613.278 732.885 615.597 714.663C621.561 666.621 650.717 605.326 757.072 549.001C828.969 510.568 842.222 431.382 847.192 419.786C854.481 400.569 852.493 384.334 837.252 368.762C809.752 340.599 779.933 315.088 743.819 298.522C693.127 274.666 641.44 275.992 590.085 295.208C581.14 298.522 583.128 291.564 582.465 288.582C575.507 254.787 563.911 222.317 547.345 191.836C513.881 131.535 467.165 87.1378 397.587 72.2283C387.316 69.909 376.714 69.2464 366.111 67.9211C361.142 67.2585 358.491 68.5838 359.816 74.5476C369.093 118.945 382.015 161.685 410.177 198.131C423.099 215.028 440.659 226.293 457.888 237.89C476.11 250.48 495.327 262.076 512.556 276.323C528.791 290.239 542.044 306.473 548.339 329.666C546.019 327.347 545.025 326.684 544.363 325.69C491.351 241.534 387.647 204.426 283.281 234.908C273.672 237.558 274.998 240.871 279.636 247.829C309.787 294.214 351.202 327.347 400.238 352.858C436.683 371.744 474.454 387.316 515.538 392.617C534.092 394.936 525.146 407.527 528.128 422.436C532.104 445.629 541.712 452.918 538.399 450.93C475.448 425.75 423.099 415.81 379.695 415.81C200.45 415.81 171.294 586.772 172.619 588.76C169.968 587.766 126.896 571.863 117.288 614.935C107.348 658.669 165.992 687.163 165.992 687.163C173.613 635.476 219.998 624.212 225.631 622.887C220.992 625.537 190.842 643.097 181.565 691.139C173.613 733.548 207.077 770.988 219.667 783.909H145.451C55.9935 701.41 0 582.796 0 451.593C0 202.107 201.775 0 451.261 0C700.747 0 902.854 202.107 902.854 451.593ZM574.845 783.909H527.134C529.453 781.59 531.11 778.276 531.773 774.632C535.748 751.439 515.869 746.801 515.869 746.801H405.87C405.87 746.801 449.605 744.813 489.695 728.247C529.453 711.349 561.592 681.862 567.224 673.91C548.67 717.644 557.616 754.421 565.236 772.975C566.893 777.614 570.537 781.59 574.845 783.909Z"
				fill="#FF4702"
			/>
		</svg>
	)
}

export default RabbitSVG
