import { LoadDashboardPortal } from "@/grafana"
import { getSelectedOrg } from "@/utils/utils"
import type { FC } from "react"

const OVERVIEW_DASHBOARD_UID = "ai-impact-dashboard" //"d51c3b56-600d-4f25-80e7-cdc1726d1388"
const TOOLS_DASHBOARD_UID = "d51c3b56-600d-4f25-80e7-cdc1726d13881"

export const OverviewDashboard: FC = () => (
	<LoadDashboardPortal
		{...{
			uid: OVERVIEW_DASHBOARD_UID,
			slug: "code-rabbit",
			queryParams: {
				"var-org_id": getSelectedOrg()?.id ?? "",
			},
			hiddenVariables: ["org_id"],
		}}
	/>
)

export const ToolsDashboard: FC = () => (
	<LoadDashboardPortal
		{...{
			uid: TOOLS_DASHBOARD_UID,
			slug: "code-rabbit",
			queryParams: {
				"var-org_id": getSelectedOrg()?.id ?? "",
			},
			hiddenVariables: ["org_id"],
		}}
	/>
)
