import { FormControl } from "@/components/ui/form"
import { Switch } from "@/components/ui/switch"
import { Chip } from "@mui/material"
import type { WidgetProps } from "@rjsf/utils"
import { useConfigCtx } from "../../context"
import { changeToTitleCase, getNiceNameAndDescription } from "../utils"

export const CheckBoxWidget = (props: WidgetProps) => {
	const { checkIfRepoSettings } = useConfigCtx()
	const { niceDescription, niceName, version } = getNiceNameAndDescription(
		props.schema.description,
	)
	return (
		<div className="flex max-w-screen-md flex-row justify-between gap-4 pb-2 pt-2">
			<div>
				<div
					className="font-500 font-inter not-italic leading-5 "
					id="label-email"
				>
					{niceName || changeToTitleCase(props.label.replace(/_/g, " "))}
					{version ? <Chip label={version} className="ml-2" /> : null}
				</div>
				{props.schema.description && (
					<p className="max-w-3xl pt-1 text-sm font-light not-italic leading-[20px] text-muted-foreground ">
						{niceDescription || props.schema.description}
					</p>
				)}
			</div>
			<div className="ml-4 mt-4 sm:col-span-2 sm:mt-0">
				<FormControl>
					<Switch
						checked={props.value}
						onCheckedChange={props.onChange}
						disabled={checkIfRepoSettings()}
					/>
				</FormControl>
			</div>
		</div>
	)
}
