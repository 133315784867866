import * as Sentry from "@sentry/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import App from "./App"
import ErrorBoundary from "./components/Sentry/Sentry"
import { TooltipProvider } from "./components/ui/tooltip"
import { FirebaseProvider } from "./firebase"
import "./index.css"
import { trpc, trpcClient } from "./trpc"

const sampleRate = import.meta.env.MODE === "production" ? 0.2 : 1.0
Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [Sentry.browserTracingIntegration()],
	environment: import.meta.env.MODE || "production",
	// Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
	tracesSampleRate: sampleRate,
	tracePropagationTargets: [
		// Exclude HubSpot, Mixpanel and LinkedIn API requests from tracing
		/^(?!.*(api\.hubspot\.com|api-js\.mixpanel\.com|us\.i\.posthog\.com|conversions-config\.reddit\.com|www\.redditstatic\.com|px\.ads\.linkedin\.com)).*$/,
	],
	ignoreErrors: [
		"Non-Error exception captured",
		"Non-Error promise rejection captured",
	],
	beforeSend: event => {
		if (window.location.hostname === "localhost") {
			return null
		}
		return event
	},
})

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
		},
		mutations: {
			retry: false,
		},
	},
})

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<ErrorBoundary>
			<ToastContainer />
			<trpc.Provider client={trpcClient} queryClient={queryClient}>
				<QueryClientProvider client={queryClient}>
					<BrowserRouter>
						<TooltipProvider delayDuration={0}>
							<FirebaseProvider>
								<App />
							</FirebaseProvider>
						</TooltipProvider>
					</BrowserRouter>
					{import.meta.env.DEV && <ReactQueryDevtools initialIsOpen={false} />}
				</QueryClientProvider>
			</trpc.Provider>
		</ErrorBoundary>
	</React.StrictMode>,
)
