import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

interface TabsPayload {
	title: string
	id: string
}

export const useTabChange = (defaultTab: string, paramName = "tab") => {
	const [search, setSearch] = useSearchParams()
	const tabID = search.get(paramName)

	useEffect(() => {
		if (!tabID) {
			setSearch(appendURLParams(search, defaultTab, paramName))
		}
	}, [tabID])

	const onChangeTab = (tab: TabsPayload) => {
		if (tabID !== tab.id) {
			setSearch(appendURLParams(search, tab.id, paramName))
		}
	}

	return { onChangeTab, tabID }
}

function appendURLParams(
	prev: URLSearchParams,
	value: string,
	paramName: string,
) {
	const newParams = new URLSearchParams(prev)
	newParams.set(paramName, value)
	return newParams
}
