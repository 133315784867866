import React from "react"

interface GitlabUserDescriptionProps {
	children?: React.ReactNode
}

const GitlabUserDescription: React.FC<GitlabUserDescriptionProps> = ({
	children,
}) => {
	return (
		<>
			<div className=" w-full">
				<div className="flex w-full flex-row justify-between">
					<div className="w-1/2">
						<p className="mb-3 text-sm font-light text-muted-foreground">
							This integration enables CodeRabbit to access the GitLab API of
							the specified GitLab organization using an authenticated Personal
							Access Token. This token can be provided by either the default
							CodeRabbit user or a user from your organization.
						</p>
						{children ? children : null}
					</div>
				</div>
			</div>
		</>
	)
}

export default GitlabUserDescription
