import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp"
import type { AccordionProps } from "@mui/material/Accordion"
import MuiAccordion from "@mui/material/Accordion"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import type { AccordionSummaryProps } from "@mui/material/AccordionSummary"
import MuiAccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import type { SyntheticEvent } from "react"
import { useState } from "react"
import type { CRHandlerMetricsAllReposResp } from "../../typings/coderabbitHandler"
import "./Accordion.css"

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
	borderRadius: "var(--Medium, 4px)",
	"&:not(:last-child)": {
		borderBottom: 0,
	},
	color: "black",
	backgroundColor: "#F5E9DF !important",
	"&:before": {
		display: "none",
	},
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor: "transparent",
	flexDirection: "row-reverse",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(90deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
	},
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	backgroundColor: "#f5f5f5",
	fontWeight: 400,
}))

interface AccordionDataToShow {
	readonly Week: string[]
	readonly Month: string[]
	readonly Year: string[]
}

interface CollapsibleAccordionProps {
	readonly data: CRHandlerMetricsAllReposResp | undefined
	readonly accordionDataToShow: AccordionDataToShow
	readonly activeTab: keyof AccordionDataToShow
}

const CollapsibleAccordion: React.FC<CollapsibleAccordionProps> = props => {
	const [expanded, setExpanded] = useState<boolean>(true)
	const { data, accordionDataToShow, activeTab } = props

	const handleChange = (_: SyntheticEvent, newExpanded: boolean) => {
		setExpanded(newExpanded)
	}

	return (
		<div>
			<Accordion expanded={expanded} onChange={handleChange}>
				<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
					<Typography>All</Typography>
				</AccordionSummary>
				<AccordionDetails>
					{accordionDataToShow[activeTab].includes("Pull Requests") && (
						<div className="dashboard-typo-div">
							<div className="dashboard-typo-div-text">
								<Typography className="options-text">Pull Requests</Typography>
							</div>
							<div className="dashboard-typo-div-count">
								<Typography className="options-text pull-request-count-color">
									{data?.total_pr_count}
								</Typography>
							</div>
						</div>
					)}
					{accordionDataToShow[activeTab].includes("Reviews") && (
						<div className="dashboard-typo-div">
							<div className="dashboard-typo-div-text">
								<Typography className="options-text">Reviews</Typography>
							</div>
							<div className="dashboard-typo-div-count">
								<Typography className="options-text reviews-count-color">
									{data?.total_review_count}
								</Typography>
							</div>
						</div>
					)}
					{accordionDataToShow[activeTab].includes("Actionable Items") && (
						<div className="dashboard-typo-div">
							<div className="dashboard-typo-div-text">
								<Typography className="options-text">
									Actionable Items
								</Typography>
							</div>
							<div className="dashboard-typo-div-count">
								<Typography className="options-text actionable-count-color">
									{data?.total_actionable_cmnts}
								</Typography>
							</div>
						</div>
					)}
					{accordionDataToShow[activeTab].includes("Optional suggestions") && (
						<div className="dashboard-typo-div">
							<div className="dashboard-typo-div-text">
								<Typography className="options-text">
									Optional suggestions{" "}
								</Typography>
							</div>
							<div className="dashboard-typo-div-count">
								<Typography className="options-text supressed-count-color">
									{data?.total_optnl_cmnts}
								</Typography>
							</div>
						</div>
					)}
				</AccordionDetails>
			</Accordion>
		</div>
	)
}

export default CollapsibleAccordion
