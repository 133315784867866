export const SYSTEM_MESSAGE_MAX_CHARACTER_COUNT = 250

export const FILE_PATH_INSTRUCTIONS_MAX_CHARACTER_COUNT = 3000

export const ORGANIZATION = "Organization"

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const Plans = {
	LITE_MONTHLY: "CRB_LITE_MONTHLY_SUBSCRIPTION_PER_SEAT-USD-Monthly",
	LITE_ANNUAL: "CRB_LITE_ANNUAL_SUBSCRIPTION_PER_SEAT-USD-Yearly",
	PRO_MONTHLY: "CRB_PRO_MONTHLY_SUBSCRIPTION_PER_SEAT-USD-Monthly",
	PRO_ANNUAL: "CRB_PRO_ANNUAL_SUBSCRIPTION_PER_SEAT-USD-Yearly",
	PRO_LEGACY_ANNUAL: "CRB_ANNUAL_SUBSCRIPTION_PER_SEAT-USD-Yearly",
	PRO_LEGACY_MONTHLY: "CRB_MONTHLY_SUBSCRIPTION_PER_SEAT-USD-Monthly",
} as const

export const ProAndProLegacyPlans = [
	Plans.PRO_MONTHLY,
	Plans.PRO_ANNUAL,
	Plans.PRO_LEGACY_ANNUAL,
	Plans.PRO_LEGACY_MONTHLY,
] as const
