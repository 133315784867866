import type { FieldErrorProps } from "@rjsf/utils"
import type { FC } from "react"

export const FieldErrorTemplate: FC<FieldErrorProps> = props => {
	const { errors } = props
	return (
		<div className="mb-2">
			{errors?.map((error, i) => (
				<div
					key={i}
					className="relative w-2/4 rounded border border-red-400 bg-red-100 px-4 py-1 text-red-700"
				>
					<strong className="font-bold">Error: </strong>
					<span className="block sm:inline">{error}</span>
				</div>
			))}
		</div>
	)
}
