import type {
	AxiosError,
	AxiosInstance,
	AxiosResponse,
	InternalAxiosRequestConfig,
} from "axios"
import axios from "axios"

import * as ApiConstants from "./ApiConstants"

export default class APIService {
	static axiosClient: AxiosInstance
	token: string | null
	static instance?: APIService

	constructor() {
		this.token = null
		APIService.axiosClient = axios.create(ApiConstants.getAPIConfig())
		this.configureInterceptors()
	}

	static getInstance = () => {
		if (!APIService.instance) {
			APIService.instance = new APIService()
		}
		return APIService.instance
	}

	updateAuthToken = (token: string) => {
		this.token = token
		APIService.axiosClient.defaults.headers.common.Authorization = `Bearer ${token}`
	}

	requestHandler = (request: InternalAxiosRequestConfig) => {
		request.headers["x-coderabbit-organization"] =
			sessionStorage.getItem("org_id") || ""

		if (request.method === "get" || request.method === "put") {
			request.headers.Authorization =
				"Bearer " + sessionStorage.getItem("accessToken")
		}

		return request
	}

	errorHandler = (error: AxiosError) => {
		return error
	}

	successHandler = (response: any) => {
		return response
	}

	configureInterceptors = () => {
		APIService.axiosClient.interceptors.request.use(request =>
			this.requestHandler(request),
		)
		APIService.axiosClient.interceptors.response.use(
			response => this.successHandler(response),
			error => this.errorHandler(error),
		)
	}

	get = async <T = any, R = AxiosResponse<T>, D = any>(apiPath: string) => {
		try {
			return await APIService.axiosClient
				.get<T, R, D>(apiPath)
				.then(response => {
					// this.handleErrorModal(apiPath, API_REQUESTS_TYPE.GET, response);
					return response
				})
				.catch(error => {
					// const errorResponse = this.errorHandler(error);
					return error
					// this.handleErrorModal(apiPath, API_REQUESTS_TYPE.GET, errorResponse);
				})
		} catch (err) {
			throw Error(`Error in Get API:  ${err}`)
		}
	}

	delete = async (apiPath: string) => {
		try {
			return await APIService.axiosClient
				.delete(apiPath)
				.then(response => response)
		} catch (error) {
			throw Error(`Error in Delete API ${error}`)
		}
	}

	post = async (
		apiPath: string,
		payload?: any,
		transformResponse?: /* AxiosResponseTransformer */ (data: any) => any,
		transformRequest?: /* AxiosRequestTransformer */ (data: any) => any,
	) => {
		try {
			return await APIService.axiosClient
				.post(apiPath, JSON.stringify(payload), {
					headers: APIService.axiosClient.defaults.headers.common,
					transformRequest: [
						data => {
							return transformRequest ? transformRequest(data) : data
						},
					],
					transformResponse: res =>
						transformResponse
							? transformResponse(JSON.parse(res))
							: JSON.parse(res),
					validateStatus: () => {
						return true
					},
				})
				.then(response => response)
		} catch (error) {
			throw Error(`Error in Post API ${error}`)
		}
	}

	put = async <T = any, R = AxiosResponse<T>, D = any>(
		apiPath: string,
		payload?: any,
	) => {
		try {
			return await APIService.axiosClient
				.put<T, R, D>(apiPath, payload)
				.then(response => response)
		} catch (error) {
			throw Error(`Error in Put API ${error}`)
		}
	}

	patch = async (apiPath: string, payload?: any) => {
		try {
			return await APIService.axiosClient
				.patch(apiPath, payload)
				.then(response => response)
		} catch (error) {
			throw Error(`Error in Patch API ${error}`)
		}
	}
}
