import type { SelfHostedDomainData } from "@/typings"
import { PROVIDERS } from "@/utils/providers"

export const GITLAB_SELF_HOSTED_LS_KEY = "selfHostedDomainData"
export const GITHUB_SELF_HOSTED_LS_KEY = "githubSelfHosted"

export function getSelfHostedData(
	provider:
		| typeof PROVIDERS.GITHUB_SELF_HOSTED
		| typeof PROVIDERS.GITLAB_SELF_HOSTED,
) {
	try {
		if (provider === PROVIDERS.GITHUB_SELF_HOSTED) {
			const githubSelfHostedLS = localStorage.getItem(GITHUB_SELF_HOSTED_LS_KEY)

			return githubSelfHostedLS
				? (JSON.parse(githubSelfHostedLS) as SelfHostedDomainData)
				: null
		}

		if (provider === PROVIDERS.GITLAB_SELF_HOSTED) {
			const gitlabSelfHostedLS = localStorage.getItem(GITLAB_SELF_HOSTED_LS_KEY)

			return gitlabSelfHostedLS
				? (JSON.parse(gitlabSelfHostedLS) as SelfHostedDomainData)
				: null
		}

		console.error("Unhandled provider", provider)
	} catch (e) {
		console.error("Failed to parse selfHostedDomainData", e)
	}

	return null
}
