import type { LoadableApp, loadMicroApp } from "qiankun"
import { createContext, useContext, type Dispatch } from "react"
import type { GrafanaProps, GrafanaUtils } from "./utils"

export declare interface GrafanaPortal {
	uid: string
	version?: number | undefined
	slug: string
	queryParams: Record<string, string>
	hiddenVariables?: string[] | undefined
	controlsContainer?: string | null | undefined
}

export declare interface GrafanaContextProps {
	grafanaProvider:
		| (LoadableApp<Partial<GrafanaProps>> & {
				container: HTMLElement | string
				configuration?: Parameters<typeof loadMicroApp>[1]
		  })
		| null
	grafanaPortal: GrafanaPortal
	grafanaError: Record<string, unknown> | string | unknown | null
	action: Dispatch<Action> | null
	grafanaUtils: GrafanaUtils | null
	isGrafanaBroken: boolean
}

export const grafanaContextInitial: GrafanaContextProps = {
	grafanaProvider: null,
	grafanaPortal: {
		uid: "",
		version: undefined,
		slug: "",
		queryParams: {},
		hiddenVariables: undefined,
		controlsContainer: null,
	},
	grafanaError: null,
	action: null,
	grafanaUtils: null,
	isGrafanaBroken: false,
}

export const GrafanaContext = createContext<GrafanaContextProps>(
	grafanaContextInitial,
)
export const useGrafanaContext = () => useContext(GrafanaContext)

export type ContextAction =
	| "SET_GRAFANA_ERROR"
	| "SET_GRAFANA_PORTAL"
	| "SET_GRAFANA_PROVIDER"

type Action =
	| {
			type: "RESET_GRAFANA_PORTAL"
	  }
	| {
			type: "SET_GRAFANA_BROKEN"
			payload: GrafanaContextProps["isGrafanaBroken"]
	  }
	| {
			type: "SET_GRAFANA_ERROR"
			payload: GrafanaContextProps["grafanaError"]
	  }
	| {
			type: "SET_GRAFANA_PORTAL"
			payload: GrafanaContextProps["grafanaPortal"]
	  }
	| {
			type: "SET_GRAFANA_PROVIDER"
			payload: GrafanaContextProps["grafanaProvider"]
	  }
	| {
			type: "SET_GRAFANA_UTILS"
			payload: GrafanaContextProps["grafanaUtils"]
	  }

export declare type GrafanaReducer = (
	state: GrafanaContextProps,
	action: Action,
) => GrafanaContextProps

export const grafanaReducer: GrafanaReducer = (state, action) => {
	switch (action.type) {
		case "SET_GRAFANA_PROVIDER":
			return {
				...state,
				grafanaProvider: action.payload,
			}
		case "SET_GRAFANA_PORTAL":
			return {
				...state,
				grafanaPortal: action.payload,
			}
		case "SET_GRAFANA_ERROR":
			return {
				...state,
				grafanaError: action.payload,
			}
		case "RESET_GRAFANA_PORTAL":
			return {
				...state,
				grafanaPortal: grafanaContextInitial.grafanaPortal,
			}
		case "SET_GRAFANA_UTILS":
			return {
				...state,
				grafanaUtils: action.payload,
			}
		case "SET_GRAFANA_BROKEN":
			return {
				...state,
				isGrafanaBroken: action.payload,
			}
		default:
			return state
	}
}
