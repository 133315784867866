import React, { useCallback } from "react"
import { IoCloseOutline } from "react-icons/io5"
import { LuMenu } from "react-icons/lu"

interface NavHeaderProps {
	isCollapsed: boolean
	setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>
	contents: React.ReactNode
}

const NavHeader: React.FC<NavHeaderProps> = ({
	isCollapsed,
	setIsCollapsed,
	contents,
}) => {
	const toggleCollapse = useCallback(() => {
		setIsCollapsed(prev => !prev)
	}, [setIsCollapsed])

	return (
		<div className="flex h-20 w-full items-center border-b bg-white pl-4 pr-8 shadow-sm md:hidden">
			<div>{contents}</div>
			<div
				className={`pointer-events-none z-20 flex flex-grow justify-end md:hidden ${!isCollapsed ? "text-white" : "text-black"}`}
			>
				<button className="pointer-events-auto" onClick={toggleCollapse}>
					{isCollapsed ? <LuMenu size={20} /> : <IoCloseOutline size={30} />}
				</button>
			</div>
		</div>
	)
}

export default NavHeader
