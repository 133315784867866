import { trpc } from "@/trpc"
import { useAllOrgs } from "@/utils/getAllOrgs"
import { useProvider } from "@/utils/providers"
import {
	getCookie,
	getSelectedOrg,
	sentryCaptureException,
} from "@/utils/utils.ts"
import axios from "axios"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import type { FormData } from "../../components/AnalyticsPopup/Popup"
import PopupForm from "../../components/AnalyticsPopup/Popup"
import Button from "../../components/Button"
import Loader from "../../components/Loader/Loader"
import RabbitSVG from "../../svg/rabbit"
import { useHubspotUpdater } from "./installation"

export default function InstallationMessage() {
	const [loader, setLoader] = useState<boolean>(true)
	const [popupOpen, setPopupOpen] = useState(true)
	const [email, setEmail] = useState<string>("")
	const { provider } = useProvider()

	const twclid = getCookie("twclid")

	const { fetchOrganizations } = useAllOrgs()

	const user_id = sessionStorage.getItem("user_id")

	const subscriber_id = sessionStorage.getItem("subscriber_id")

	const selectedOrg = getSelectedOrg()
	const host_url = sessionStorage.getItem("host_url")

	const { updateHubspotContact } = useHubspotUpdater()

	useEffect(() => {
		const fetchData = async () => {
			try {
				await fetchOrganizations(true)
			} catch (err) {
				sentryCaptureException("InstallationMessage: fetchData failed: ", err)
			} finally {
				setLoader(false)
			}
		}

		void fetchData()
	}, [])

	// Fetch user data only if user_id is present
	const { data: user } = trpc.users.getUser.useQuery(
		{
			provider_user_id: user_id || "",
			provider: provider || "",
			host_url: host_url || undefined,
		},
		{ enabled: !!user_id && !!provider },
	)

	const mpSignUpCompleted = trpc.mixpanel.signUpCompleted.useMutation()
	const updateContactCompany = trpc.hubspot.updateContactCompany.useMutation()

	const updateTwitter = trpc.twitter.updateAnalytics.useMutation()
	const updateHearAbout = trpc.hubspot.updateContactHearAbout.useMutation()

	useEffect(() => {
		if (user?.data.email) {
			setEmail(user.data.email.trim())
		}
	}, [user])

	const handleClosePopup = () => {
		setPopupOpen(false)
	}

	async function handleFormDataSubmit(formData: FormData) {
		// update Mixpanel with sign up completed
		if (subscriber_id && provider)
			await mpSignUpCompleted
				.mutateAsync({
					user_id: subscriber_id,
					provider: provider,
					workEmail: formData.email,
					selfHostedDomain: host_url || "",
					scope: selectedOrg?.type || "",
					orgName: selectedOrg?.organization_name || "",
					orgId: selectedOrg?.id || "",
					memberCount: selectedOrg?.memberCount || 0,
				})
				.catch(err => {
					sentryCaptureException(
						"handleFormDataSubmit: signUpCompleted API failed on Installation Message",
						err,
					)
				})

		const sameEmail = formData.email.trim() === email

		// update signup completed and (email if necessary)
		await updateHubspotContact(formData, email, sameEmail).catch(err => {
			sentryCaptureException(
				"handleFormDataSubmit: updateHubspotContact API failed",
				err,
			)
		})

		// Update Twitter with twclid if present
		if (twclid) {
			await updateTwitter
				.mutateAsync({
					twclid: twclid,
					isOrg: selectedOrg?.type === "Organization",
				})
				.catch(err => {
					sentryCaptureException(
						"handleFormDataSubmit: updateTwitter API failed",
						err,
					)
				})
		}

		// Update contact's "company" property
		const company = formData.work
		if (company) {
			try {
				await updateContactCompany.mutateAsync({
					email: formData.email.trim(),
					company: company,
				})
			} catch (err) {
				sentryCaptureException(
					"handleFormDataSubmit: updateContactCompany API failed",
					err,
				)
			}
		}

		// Update hear about information
		if (formData.hearAbout) {
			try {
				await updateHearAbout.mutateAsync({
					email: formData.email.trim(),
					hearAbout: formData.hearAbout,
					other: formData.specificSource,
				})
			} catch (err) {
				sentryCaptureException(
					"handleFormDataSubmit: Hear about update failed",
					err,
				)
			}
		}

		if (sameEmail) {
			return
		}

		// Update Chargebee with new email
		await axios
			.post(`${import.meta.env.VITE_BILLING_FUNC_URL}/updateOrgEmail`, {
				email: formData.email.trim(),
				organization_id: sessionStorage.getItem("org_id"),
			})
			.catch(err => {
				sentryCaptureException(
					"handleFormDataSubmit: updateEmail API failed",
					err,
				)
			})
	}

	return (
		<>
			<div>
				{loader ? (
					<Loader size="small" />
				) : (
					<div className="container mx-auto my-10 rounded-2xl bg-white p-20 text-center shadow-md">
						<div className="text-center">
							<div className="flex items-center justify-center">
								<RabbitSVG width={200} />
							</div>
							<h1 className="mt-10 font-poppins text-4xl text-[#242424]">
								Congratulations! 🎉
							</h1>
							<h1 className="mt-10 font-poppins text-xl text-[#242424]">
								CodeRabbit is now installed on the repositories you&apos;ve
								chosen.
							</h1>
							<h1 className="mx-auto mb-10 mt-2 max-w-5xl font-poppins text-xl text-[#242424]">
								Create pull requests as you usually do. CodeRabbit will monitor
								your chosen repositories and automatically review any new pull
								requests.
							</h1>

							<div className="font-poppins text-[#242424]">
								Got questions? Head over to the &apos;Get Started&apos; section
								under our{" "}
								<a className="text-indigo-600" target="_blank" href="/docs">
									Docs
								</a>{" "}
								for more detailed guidance.
							</div>

							<div className="mb-10 mt-1">
								Reviews can be customized using a yaml file or under the
								repositories in the UI. To add additional repositories, navigate
								to the{" "}
								<a className="text-indigo-600" href="/settings/repositories">
									Repositories
								</a>{" "}
								page.
							</div>
							<Link to="/integrations">
								<div className="mb-1 font-poppins text-[#242424]">
									Do you use Linear or Jira?
								</div>
								<Button title="Consider Integrating with CodeRabbit" />
							</Link>
						</div>
						{popupOpen && (
							<PopupForm
								onClose={handleClosePopup}
								onSubmit={handleFormDataSubmit}
								email={email}
								step={1}
								work={
									selectedOrg?.type === "Organization"
										? selectedOrg.organization_name
										: ""
								}
							/>
						)}
						<div className="mb-10 mt-4">
							Tell us how you came across CodeRabbit{" "}
							<a
								className="cursor-pointer text-indigo-600"
								onClick={() => {
									setPopupOpen(true)
								}}
							>
								here
							</a>
						</div>
					</div>
				)}
			</div>
		</>
	)
}
