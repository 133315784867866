import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { SESSION_STORAGE_TENANT_ID } from "@/firebase"
import { logger } from "@/lib/utils"
import { trpc } from "@/trpc"
import type { FC } from "react"
import * as React from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"

interface FormData {
	email: string
}

export const LOCAL_STORAGE_EMAIL_KEY = "emailForSignIn"
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

interface SignInWithEmailLinkProps {
	tenantId: string
}

export const SignInWithEmailLinkForm: FC<
	React.PropsWithChildren<SignInWithEmailLinkProps>
> = ({ tenantId, children }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>()

	const [message, setMessage] = React.useState<string | null>(null)
	const { mutate: signInWithEmailLink, isLoading } =
		trpc.signInWithEmailLink.sendEmailLink.useMutation()

	const onSubmit = (data: FormData) => {
		if (!tenantId.length) {
			toast.error("Organization name is required")
			return
		}
		sessionStorage.setItem(SESSION_STORAGE_TENANT_ID, tenantId)

		signInWithEmailLink(
			{
				...data,
				tenantId,
				url: window.location.href,
			},
			{
				onSuccess: res => {
					if (!res.isSuccess) {
						logger.error("Failed to send sign-in link")
						toast.error(
							"User does not exist, to create an account please contact your organization admin.",
						)

						return
					}
					toast.success("SignIn link sent to your email")
					window.localStorage.setItem(LOCAL_STORAGE_EMAIL_KEY, data.email)
				},
				onError: error => {
					logger.error(error)
					toast.error("Failed to send sign-in link")
					setMessage("Failed to send sign-in link.")
				},
			},
		)
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{children}
			<div>
				<Input
					id="email"
					type="email"
					placeholder="Email"
					{...register("email", {
						required: "Email is required",
						pattern: {
							value: EMAIL_REGEX,
							message: "Invalid email address",
						},
					})}
				/>
				{errors.email && (
					<p className="mt-1 text-sm text-red-500">{errors.email.message}</p>
				)}
			</div>
			<Button
				type="submit"
				className="mt-3 w-full max-w-80 text-secondary-foreground text-white"
				disabled={isLoading}
			>
				Sign in with email link
			</Button>
			{message && <p className="mt-1 text-sm text-green-400">{message}</p>}
		</form>
	)
}

interface AskForEmailPopUpProps {
	onSubmit: (p: FormData) => void
	open: boolean
	setOpen: (open: boolean) => void
}
/**
 * If user tries to sign in with email link from a different device/browser, they will be asked to confirm their email.
 */
export const AskForEmailPopUp: FC<AskForEmailPopUpProps> = ({
	onSubmit,
	open,
	setOpen,
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>()
	return (
		<Dialog
			open={open}
			onOpenChange={open => {
				setOpen(open)
			}}
		>
			<DialogContent>
				<DialogTitle>Confirm you email address</DialogTitle>
				<div>
					<p className="mb-3 font-poppins text-sm font-normal not-italic leading-4 text-[#606060]">
						Looks like you are logging in from a different browser or device.
						Please confirm your email address to continue.
					</p>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div>
							<Input
								type="email"
								placeholder="Email"
								{...register("email", {
									required: "Email is required",
									pattern: {
										value: EMAIL_REGEX,
										message: "Invalid email address",
									},
								})}
							/>
							{errors.email && (
								<p className="mt-1 text-sm text-red-500">
									{errors.email.message}
								</p>
							)}
						</div>
						<Button
							type="submit"
							className="mt-3 w-full text-secondary-foreground"
						>
							Confirm Email
						</Button>
					</form>
				</div>
			</DialogContent>
		</Dialog>
	)
}
