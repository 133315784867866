import {
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion"
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog"
import { Popover, PopoverTrigger } from "@/components/ui/popover"
import type { PgLearning } from "@/typings/coderabbitHandler"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Divider } from "@mui/material"
import { PopoverContent } from "@radix-ui/react-popover"
import type { ColumnDef } from "@tanstack/react-table"
import moment from "moment"
import { useState, type FC } from "react"
import { LuFileEdit } from "react-icons/lu"
import { MdOutlineDeleteOutline } from "react-icons/md"
import { MetadataInfo, RepoContext } from "./components/HelperComponents"
import { DeleteLearning, EditLearning } from "./forms"
import { createCommentLink } from "./utils"

export const columns = (): ColumnDef<PgLearning>[] => [
	{
		accessorKey: "learning",
		cell: ({ row }) => {
			const commentLink = createCommentLink({
				top_level_comment_id: row.original.top_level_comment_id,
				pull_request: row.original.pull_request,
				repo_owner: row.original.repo_owner,
				repo_name: row.original.repo_name,
			})

			return (
				<AccordionItem
					value={row.original.id}
					className="border-light-gray w-full rounded-md border font-poppins"
				>
					<AccordionTrigger className="grid grid-cols-[1fr_auto_auto] p-5 hover:no-underline">
						<div
							className="max-w-lg whitespace-normal break-words text-left font-medium"
							style={{ maxWidth: "98%" }}
						>
							{row.original.learning}
						</div>
						<LearningActions learning={row.original} />
					</AccordionTrigger>
					<AccordionContent>
						<Divider />
						<div className="col-auto grid gap-y-[12px] p-5">
							<div className="text-base font-medium">Metadata</div>
							<div className="align-start md:flex md:items-center md:gap-6">
								<MetadataInfo
									info={[
										{
											title: "Created on",
											value: moment(row.original.createdAt).format(
												"MMMM Do YYYY, h:mm:ss a",
											),
										},
										{
											title: "Updated on",
											value: moment(row.original.updatedAt).format(
												"MMMM Do YYYY, h:mm:ss a",
											),
										},
										{
											title: "Created by",
											value: row.original.user,
										},
									]}
								/>
							</div>
							<div className="flex flex-col gap-6 rounded bg-gray-100 p-4">
								<RepoContext
									info={[
										{
											title: "Repository",
											value: row.original.repo_name,
										},
										...(row.original.file
											? [
													{
														title: "File",
														value: row.original.file,
														divProps: {
															className: "text-crb-primary",
														},
													},
												]
											: []),
										...(row.original.pull_request
											? [
													{
														title: "Pull Request",
														value: commentLink ? (
															<a
																href={commentLink}
																target="__blank"
																className="font-medium text-crb-primary underline"
															>
																{row.original.pull_request}
															</a>
														) : (
															row.original.pull_request
														),
														divProps: {
															className: "text-crb-primary",
														},
													},
												]
											: []),
										...(row.original.url
											? [
													{
														title: "URL",
														value: (
															<a
																href={row.original.url}
																target="__blank"
																className="font-medium text-crb-primary underline"
															>
																{row.original.url}
															</a>
														),
														divProps: {
															className: "text-crb-primary",
														},
													},
												]
											: []),
									]}
								/>
							</div>
						</div>
					</AccordionContent>
				</AccordionItem>
			)
		},
	},
]

interface LearningActionsProps {
	learning: PgLearning
}

const LearningActions: FC<LearningActionsProps> = ({ learning }) => {
	const [open, setOpen] = useState<boolean>(false)
	const [openEdit, setOpenEdit] = useState<boolean>(false)
	const [openDelete, setOpenDelete] = useState<boolean>(false)

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<MoreVertIcon
					className="cursor-pointer"
					onClick={e => {
						e.stopPropagation()
					}}
				/>
			</PopoverTrigger>
			<PopoverContent side="left" align="start">
				<div className="flex min-w-[200px] flex-col rounded border border-gray-300 bg-white shadow-md">
					<Dialog open={openEdit} onOpenChange={setOpenEdit}>
						<DialogContent>
							<EditLearning
								setShowEditLearning={setOpenEdit}
								learning={learning}
							/>
						</DialogContent>

						<DialogTrigger asChild>
							<div
								className="grid cursor-pointer grid-cols-[10px_1fr] items-center gap-4 px-3 py-2 text-sm"
								onClick={e => {
									e.stopPropagation()
								}}
							>
								<div>
									<LuFileEdit />
								</div>{" "}
								<div className="text-left">Edit</div>
							</div>
						</DialogTrigger>
					</Dialog>
					<Divider />
					<Dialog open={openDelete} onOpenChange={setOpenDelete}>
						<DialogContent>
							<DeleteLearning
								setShowDeleteLearning={setOpenDelete}
								id={learning.id}
							/>
						</DialogContent>
						<DialogTrigger asChild>
							<div
								className="grid cursor-pointer grid-cols-[10px_1fr] items-center gap-4 px-3 py-2 text-sm text-red-400"
								onClick={e => {
									e.stopPropagation()
								}}
							>
								<div>
									<MdOutlineDeleteOutline fontSize="large" />
								</div>
								<div className="text-left">Delete</div>
							</div>
						</DialogTrigger>
					</Dialog>
				</div>
			</PopoverContent>
		</Popover>
	)
}
