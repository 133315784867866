import { FaTimes } from "react-icons/fa"

interface ChipProps {
	readonly name: string
	readonly onDelete?: () => void
}

const Chip: React.FC<ChipProps> = ({ name, onDelete }) => {
	return (
		<div className="flex w-fit items-center gap-1.5 rounded-md border bg-white px-2 py-0.5 text-crb-text-secondary">
			{name}
			{onDelete && (
				<FaTimes
					className="cursor-pointer text-gray-400 hover:text-gray-500"
					onClick={onDelete}
					size={12}
				/>
			)}
		</div>
	)
}

export default Chip
