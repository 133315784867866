import Search from "@/components/Search/Search"
import { Button } from "@/components/ui/button"
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import type { PgLearning } from "@/typings/coderabbitHandler"
import { Chip, Divider } from "@mui/material"
import { uniqBy } from "lodash"
import { useEffect, useState, type Dispatch, type SetStateAction } from "react"
import { FaPlus } from "react-icons/fa6"

interface LearningsFiltersTabList {
	id: "file" | "learning" | "repo_name" | "user"
	title: string
}

const TAB_LIST: LearningsFiltersTabList[] = [
	{ id: "repo_name", title: "Repository" },
	{ id: "learning", title: "Learning" },
	{ id: "file", title: "File Path" },
	{ id: "user", title: "User" },
]

export interface ChipsInfo {
	key: LearningsFiltersTabList["id"]
	value: number | string
}

interface LearningsFiltersProps<TData extends PgLearning> {
	setTableData: Dispatch<SetStateAction<TData[]>>
	learnings: TData[]
	useChips: [ChipsInfo[], Dispatch<SetStateAction<ChipsInfo[]>>]
	similaritySearchQuery: () => void
	isSimilaritySearch: boolean
}

export function LearningFilters<TData extends PgLearning>({
	setTableData,
	learnings,
	useChips,
	similaritySearchQuery,
	isSimilaritySearch,
}: LearningsFiltersProps<TData>) {
	const [search, setSearch] = useState<string>("")
	const [chips, setChips] = useChips
	const handleSearch = (search: string) => {
		setSearch(search.trim())
	}

	const handleDelete = (value: number | string) => {
		const newChips = chips.filter(chip => chip.value !== value)
		setChips(newChips)
	}

	useEffect(() => {
		if (isSimilaritySearch) {
			similaritySearchQuery()
			return
		}

		if (!chips.length) {
			setTableData(learnings)
			return
		}

		const filteredLearnings = learnings.filter(l => {
			return chips.every(chip => {
				return l[chip.key] === chip.value
			})
		})

		setTableData(filteredLearnings)
	}, [chips, isSimilaritySearch])

	return (
		<Popover>
			<div className="flex items-center gap-3 py-3 font-poppins">
				<PopoverTrigger asChild>
					<Button
						variant="outline"
						color="primary"
						className="flex items-center gap-2 p-5"
					>
						<FaPlus />
						Filters
						{chips.map((chip, i) => (
							<Chip
								key={[chip.key, chip.value, i].join(": ")}
								label={[chip.key, chip.value].join(": ")}
								variant="outlined"
								size="small"
								onDelete={() => {
									handleDelete(chip.value)
								}}
								className="m-1 mb-0.5 mt-0.5 max-w-48 overflow-hidden overflow-ellipsis whitespace-nowrap border-gray-200 bg-transparent text-[18px] font-semibold"
								sx={{ maxWidth: "180px" }}
							/>
						))}
					</Button>
				</PopoverTrigger>
			</div>
			<PopoverContent
				className="flex w-96 flex-col p-0 font-poppins"
				align="start"
			>
				<div>
					<Search
						showLabel={false}
						onSearch={handleSearch}
						fullWidth
						inputProps={{
							className:
								"border-none focus:shadow-none focus:ring-0 border-transparent w-full",
						}}
					/>
					<Divider />
				</div>
				<FiltersView {...{ search, learnings, setChips, setSearch }} />
			</PopoverContent>
		</Popover>
	)
}

interface FiltersViewProps<TData extends PgLearning> {
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	learnings: TData[]
	setChips: Dispatch<SetStateAction<ChipsInfo[]>>
}

export function FiltersView<TData extends PgLearning>({
	search,
	setSearch,
	learnings,
	setChips,
}: FiltersViewProps<TData>) {
	const [items, setItems] = useState<(number | string)[]>([])
	const [totalMatches, setTotalMatches] = useState<number>(0)
	const [currentTab, setCurrentTab] =
		useState<LearningsFiltersTabList["id"]>("file")

	const setInitialItems = () => {
		const topFiveUniqueItems = uniqBy(
			learnings.map(l => l[currentTab]).filter(isNotNull),
			item => item,
		).slice(0, 5)
		setItems(topFiveUniqueItems)
		setTotalMatches(0)
	}

	useEffect(
		() => () => {
			setInitialItems()
			setSearch("")
		},
		[],
	)

	useEffect(() => {
		if (search.trim().length === 0) {
			setInitialItems()
			return
		}

		const newItems = learnings
			.filter(l =>
				l[currentTab]?.toString().toLowerCase().includes(search.toLowerCase()),
			)
			.map(l => l[currentTab])
			.filter(isNotNull)

		const uniqueItems = uniqBy(newItems, item => item)
		setTotalMatches(uniqueItems.length)
		setItems(uniqueItems.slice(0, 5))
	}, [search, currentTab])

	return (
		<Tabs defaultValue="file" className="w-full">
			<TabsList className="w-full rounded-none">
				{TAB_LIST.map(tab => (
					<TabsTrigger
						key={tab.id}
						value={tab.id}
						onClick={() => {
							setCurrentTab(tab.id)
						}}
						className="w-full text-left"
					>
						{tab.title}
					</TabsTrigger>
				))}
			</TabsList>
			{TAB_LIST.map((tab, i) => {
				return (
					<TabsContent value={tab.id} className="w-full" key={i}>
						{!items.length ? (
							<div className="p-2 px-4 text-center text-xs font-semibold">
								No results
							</div>
						) : (
							<div className="flex items-center justify-end px-4 text-xs font-semibold text-crb-primary">
								{totalMatches
									? `matches ${totalMatches} of ${
											uniqBy(
												learnings.map(l => l[currentTab]),
												i => i,
											).length
										}`
									: ``}
							</div>
						)}
						{items.map((value, i) => {
							return (
								<div
									className="cursor-pointer p-2 px-4 text-sm hover:bg-gray-100"
									key={i}
									onClick={() => {
										setChips(prev =>
											uniqBy([...prev, { key: tab.id, value }], "value"),
										)
									}}
								>
									{value}
								</div>
							)
						})}
					</TabsContent>
				)
			})}
		</Tabs>
	)
}

function isNotNull<T>(value: T | null): value is T {
	return value !== null
}
