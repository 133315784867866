import type { FormData } from "@/components/AnalyticsPopup/Popup.tsx"
import { SanitizedHTML } from "@/components/Toast/Toast"
import { DataTable } from "@/components/ui/data-table"
import { logger } from "@/lib/utils"
import AzureBotUserModal from "@/pages/Settings/AzureUser/AzureBotUserModal.tsx"
import ChooseBotUserModal from "@/pages/Settings/GitlabUser/ChooseBotUserModal.tsx"
import { trpc } from "@/trpc"
import type { OrganizationData } from "@/typings"
import type { RepoDetails } from "@/typings/githubActionsHandler.ts"
import { useAllOrgs } from "@/utils/getAllOrgs"
import { PROVIDERS, useProvider } from "@/utils/providers"
import { handleSessionExpiration } from "@/utils/session.ts"
import {
	getCookie,
	getSelectedOrg,
	isTRPCClientError,
	sentryCaptureException,
} from "@/utils/utils.ts"
import type { PaginationState } from "@tanstack/react-table"
import axios from "axios"
import { useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import PopupForm from "../../../components/AnalyticsPopup/Popup"
import ImageButton from "../../../components/ImageButton/ImageButton"
import Loader from "../../../components/Loader/Loader"
import Search from "../../../components/Search/Search"
import { columns } from "./Columns"

export default function RepositoryList() {
	const [isLoadingRepos, setIsLoadingRepos] = useState<boolean>(false)
	const [isLoadingOrgs, setIsLoadingOrgs] = useState<boolean>(false)
	const [loaderMessage, setLoaderMessage] = useState<string>("")
	const isRecentlyCreated =
		sessionStorage.getItem("recently_created") === "true"
	const location = useLocation()
	const navigate = useNavigate()

	const {
		provider,
		isGitHubCloud,
		isGitlab,
		isSelfHosted,
		isGitlabCloud,
		isAzureDevops,
	} = useProvider()

	const user_id = sessionStorage.getItem("user_id")
	const subscriber_id = sessionStorage.getItem("subscriber_id")
	const host_url = sessionStorage.getItem("host_url")

	const signUpCompleted = trpc.mixpanel.signUpCompleted.useMutation()

	const { organizations, fetchOrganizations } = useAllOrgs()

	const [popupWasOpened, setPopupWasOpened] = useState(
		sessionStorage.getItem("popupWasOpened") === "true" || false,
	)

	const [popupOpen, setPopupOpen] = useState(
		isRecentlyCreated && isGitlab && !popupWasOpened,
	)
	const [chargebeeEmail, setChargebeeEmail] = useState<string>("")

	const [gitlabUserPopup, setGitlabUserPopup] = useState(false)
	const [azureUserPopup, setAzureUserPopup] = useState(false)

	const updateContactSignUpCompleted =
		trpc.hubspot.updateContactSignUpCompleted.useMutation()
	const updateHearAbout = trpc.hubspot.updateContactHearAbout.useMutation()
	const updateTwitter = trpc.twitter.updateAnalytics.useMutation()

	const addGitlabRepository = trpc.repositories.addGitLabRepository.useMutation(
		{
			onSuccess: () => {
				toast.success("Repository added successfully")
			},
			onError: error => {
				if (error.data?.httpStatus == 401) {
					handleSessionExpiration(navigate)
					return
				}
				if (error.data?.httpStatus == 403) {
					toast.error(
						"Error: Only Maintainers or Owners can install CodeRabbit on this repository.",
					)
					return
				}
				if (
					error.data?.httpStatus == 400 &&
					error.message.includes("is not linked to a SAML")
				) {
					const htmlContent = `User is not linked to a SAML account or has an inactive SCIM identity. For information on how to resolve this error, click <a href="https://gitlab.com/help/user/group/saml_sso/troubleshooting_scim" target="_blank" rel="noopener noreferrer">here for the troubleshooting SCIM documentation</a>.`

					toast.error(<SanitizedHTML content={htmlContent} />, {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: 0,
					})
					return
				}
			},
		},
	)

	const addAzureDevOpsRepository =
		trpc.repositories.addAzureDevOpsRepository.useMutation({
			onSuccess: () => {
				toast.success("Repository added successfully")
			},
			onError: error => {
				if (error.data?.httpStatus == 401) {
					handleSessionExpiration(navigate)
					return
				}
				if (error.data?.httpStatus == 403) {
					toast.error(
						"Error: Only Maintainers or Owners can install CodeRabbit on this repository.",
					)
					return
				}
			},
		})

	const azureUserQuery = trpc.providers.getOrgUser.useQuery(undefined, {
		enabled: isAzureDevops && !(isLoadingRepos || isLoadingOrgs),
	})
	const azureUser = azureUserQuery.data

	const { data: user } = trpc.users.getUser.useQuery(
		{
			provider_user_id: user_id || "",
			provider: provider || "",
			host_url: host_url || undefined,
		},
		{
			enabled:
				!!user_id &&
				!!provider &&
				isRecentlyCreated &&
				isGitlab &&
				!popupWasOpened,
		},
	)

	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	})

	// Pagination is done through query params when using GitLab
	const pagesVisited = useMemo(
		() => (isGitlab ? 0 : pagination.pageIndex * pagination.pageSize),
		[pagination, isGitlab],
	)

	const [selectedGroup, setSelectedGroup] = useState<OrganizationData["id"]>()
	const [searchResults, setSearchResults] = useState<RepoDetails[]>([])
	const [searchQuery, setSearchQuery] = useState<string>("")

	const trpcUtils = trpc.useUtils()

	const selectedOrg = getSelectedOrg()

	const repositoriesQuery = trpc.repositories.getAllRepos.useQuery(
		{
			provider: provider || "",
			orgName: selectedOrg?.organization_name || "",
			type: selectedOrg?.type || "",
			orgIds: (selectedGroup ? [selectedGroup] : []).toString(),
			selfHostedDomain: sessionStorage.getItem("selfHostedDomain") ?? undefined,
		},
		{ enabled: !isGitlab && !!selectedOrg },
	)

	const subGroupsQuery = trpc.repositories.getAllSubGroups.useQuery(
		{
			baseGroupId: selectedOrg?.provider_organization_id ?? "",
		},
		{
			enabled: false,
		},
	)

	const repos = repositoriesQuery.data?.repos ?? []
	const total = repositoriesQuery.data?.repos.length ?? 0
	const subGroups = subGroupsQuery.data ?? []

	useEffect(() => {
		if (import.meta.env.PROD && organizations.length) {
			const subscriber_id = sessionStorage.getItem("subscriber_id")
			const user_name = sessionStorage.getItem("login")
			const email = location.state?.email as string | undefined
			if (subscriber_id && provider && user_name) {
				const orgIds = organizations.map(org => org.id)
				identifyUserMutation.mutate({
					user_id: subscriber_id,
					provider: provider,
					scope: getSelectedOrg()?.type || "",
					name: user_name,
					email: email || "",
					orgIds: orgIds,
				})
			}
		}
	}, [organizations])

	useEffect(() => {
		// Fetch orgs and repos on initial load
		// For GitLab, repos will be fetched in the useEffect below this one
		void fetchData({ fetchOrgs: true, fetchRepos: false })
	}, [])

	useEffect(() => {
		if (isGitlab && selectedGroup) {
			// Refetch repos for gitlab when the selected group or page changes
			void fetchData({ fetchOrgs: false, fetchRepos: true })
		}
	}, [selectedGroup, pagination.pageIndex, pagination.pageSize])

	useEffect(() => {
		if (
			isRecentlyCreated &&
			isGitlabCloud &&
			popupWasOpened &&
			sessionStorage.getItem("gitlab-user-popup") !== "false"
		) {
			setGitlabUserPopup(true)
		}
	}, [isRecentlyCreated, isGitlabCloud, popupWasOpened])

	useEffect(() => {
		if (isAzureDevops && azureUserQuery.isFetched && !azureUserQuery.isError) {
			setAzureUserPopup(!azureUser)
		}
	}, [isAzureDevops, azureUserQuery.isFetched, azureUser])

	useEffect(() => {
		if (user?.data.email) {
			setChargebeeEmail(user.data.email)
		}
	}, [user])

	const handleClosePopup = () => {
		setPopupOpen(false)
		setPopupWasOpened(true)
		sessionStorage.setItem("popupWasOpened", "true")
	}

	function getSubgroupName(subGroupName: string, orgName?: string) {
		if (isGitlab) {
			return orgName === subGroupName ? "Base group" : subGroupName
		}
		return subGroupName
	}

	async function addRepo(repo: RepoDetails) {
		if (!repo.isEnabled) {
			setIsLoadingRepos(true)

			const user_id = sessionStorage.getItem("user_id")
			const login = sessionStorage.getItem("login")

			if (!user_id || !login) {
				toast.error("User not found. Please login again.")
				return
			}

			const body = {
				repositories: {
					id: repo.id,
					name: repo.name,
					orgId: repo.orgId, // projectId for azure devops
					orgName: selectedOrg?.organization_name || "",
					private: repo.private,
				},
				requester: {
					id: user_id,
					login: login,
				},
			}

			try {
				if (isAzureDevops) {
					await addAzureDevOpsRepository.mutateAsync({
						repository: body.repositories,
						requester: {
							id: user_id,
							login: login,
						},
					})
				} else {
					await addGitlabRepository.mutateAsync({
						repository: body.repositories,
						requester: {
							id: user_id,
							login: login,
						},
					})
				}
			} catch (err) {
				setIsLoadingRepos(false)
			}
		}

		await fetchRepos()
	}
	/**
	 *
	 * @param {*} providerOrgId
	 * @param {*} data
	 * @returns parent org name
	 */
	function findParentOrgName(
		providerOrgId: OrganizationData["provider_organization_id"],
	): string | null {
		const orgInfo = sessionStorage.getItem("org_info")
		if (!orgInfo) {
			logger.error("Organization info not found in session storage")
			return null
		}
		const data = JSON.parse(orgInfo) as OrganizationData[] | null
		if (data) {
			for (const org of data) {
				if (org.provider_organization_id == providerOrgId) {
					const parentId = org.parentId

					if (parentId) {
						for (const parentOrg of data) {
							if (parentOrg.provider_organization_id == parentId) {
								return `${parentOrg.organization_name}/`
							}
						}
					}
				}
			}
		}

		return null // Return null if no parent org is found
	}

	/**
	 * Get all repoes
	 * */
	async function fetchRepos() {
		if (isGitlab && !selectedGroup) {
			return
		}
		setIsLoadingRepos(true)
		const selectedOrganization = getSelectedOrg()
		if (!selectedOrganization) {
			sessionStorage.clear()
			navigate("/login")
			return
		}

		try {
			await repositoriesQuery.refetch()

			const shouldRedirect = sessionStorage.getItem("shouldRedirect")
			console.info("shouldRedirect", shouldRedirect)

			if (
				provider == "github" &&
				shouldRedirect == "true" &&
				isRecentlyCreated
			) {
				window.location.assign(import.meta.env.VITE_GITHUB_APP_URL)
				setIsLoadingRepos(false)
				sessionStorage.removeItem("app-status")
				return
			}

			// fetch subgroups
			if (
				(isGitlab || isAzureDevops) &&
				selectedOrg?.provider_organization_id
			) {
				await subGroupsQuery.refetch()
			}
		} catch (error) {
			if (axios.isAxiosError(error) && error.response?.status == 401) {
				handleSessionExpiration(navigate)
				return
			}
			if (
				axios.isAxiosError(error) &&
				error.response?.data?.message === "OAUTH_ACCESS_DENIED"
			) {
				toast.error(
					"Your organization has disabled access via OAuth. Please check you organization policies and enable third-party application access via OAuth",
				)
			}
		} finally {
			setIsLoadingRepos(false)
		}
	}

	function compareRepos(a: RepoDetails, b: RepoDetails) {
		const hasParentA = Boolean(findParentOrgName(a.orgId))
		const hasParentB = Boolean(findParentOrgName(b.orgId))

		if (hasParentA && !hasParentB) return 1
		if (!hasParentA && hasParentB) return -1
		return 0
	}

	async function handleSearch(searchQuery: string): Promise<void> {
		try {
			setSearchQuery(searchQuery)

			if (searchQuery.trim() === "") {
				// If the search query is empty, reset search results to an empty array
				setSearchResults([])
				return
			}

			let searchResultsData = []

			const existingRepo = repos.find(repo =>
				repo.name.toLowerCase().includes(searchQuery.toLowerCase()),
			)

			if (existingRepo) {
				// If repo exists in repos, filter and set the search results
				searchResultsData = repos.filter(repo =>
					repo.name.toLowerCase().includes(searchQuery.toLowerCase()),
				)
				setSearchResults(searchResultsData)
			} else {
				if (isGitlab) {
					if (!selectedOrg || !selectedGroup) {
						throw new Error("Selected group or org is missing")
					}
					const data = await trpcUtils.repositories.getFilteredRepos.fetch({
						query: searchQuery,
						type: selectedOrg.type,
						groupId: selectedGroup,
						selfHostedDomain:
							sessionStorage.getItem("selfHostedDomain") || undefined,
					})
					if (!data.isSuccess) {
						toast.error("Something went wrong, Please try again")
					} else if (data.data.repos.repos.length > 0) {
						searchResultsData = data.data.repos.repos
						setSearchResults(searchResultsData)
					} else {
						// If no search results are found, set searchResults to an empty array
						setSearchResults([])
					}
				} else {
					setSearchResults([])
				}
			}
		} catch (error) {
			toast.error("Something went wrong, Please try again")
		}
	}

	const identifyUserMutation = trpc.mixpanel.identifyUser.useMutation()

	async function fetchData({
		fetchOrgs = true,
		fetchRepos: fetchRepositories = true,
	}) {
		try {
			if (fetchOrgs) {
				setIsLoadingOrgs(true)
				setLoaderMessage("Setting up CodeRabbit... 🚀")
				await fetchOrganizations(true).catch(e => {
					if (isTRPCClientError(e)) {
						if (e.data?.code === "UNAUTHORIZED") {
							handleSessionExpiration(navigate)
							return
						}
					}
					toast.error("Something went wrong, Please try again")
				})

				if (isGitlab) {
					const selectedOrg = getSelectedOrg()
					setSelectedGroup(selectedOrg?.provider_organization_id)
				}
				if (organizations.length) {
					const shouldRedirect = !organizations.find(
						e => e.lastSubscriptionStatus !== null,
					)
					sessionStorage.setItem("shouldRedirect", shouldRedirect.toString())
					const hasActiveSubscription = organizations.some(
						item => item.enableReferral,
					)

					if (hasActiveSubscription) {
						sessionStorage.setItem("enable_referral", "active")
					}
				}

				setIsLoadingOrgs(false)
			}
			if (fetchRepositories) {
				await fetchRepos()
			}
		} catch (error) {
			logger.error("🚀 ~ file: Repository.tsx ~ fetchData ~ error:", error)
		}
	}

	let groupList = [...(selectedOrg ? [selectedOrg] : []), ...(subGroups || [])]

	if (provider === PROVIDERS.AZURE_DEVOPS) {
		groupList = subGroups
	}

	const filteredRepos = repos.filter(repo =>
		repo.name.toLowerCase().includes(searchQuery.toLowerCase()),
	)

	const reposToShow = (searchQuery.trim() !== "" ? searchResults : repos)
		.sort(compareRepos)
		.slice(pagesVisited, pagesVisited + pagination.pageSize)

	const pageCount = Math.ceil(
		(searchQuery ? filteredRepos.length : total) / pagination.pageSize,
	)

	const updateContact = trpc.hubspot.updateContactCompany.useMutation()

	async function handleFormDataSubmit(formData: FormData) {
		if (subscriber_id && provider)
			await signUpCompleted
				.mutateAsync({
					user_id: subscriber_id,
					provider: provider,
					workEmail: formData.email,
					selfHostedDomain: host_url || "",
					scope: selectedOrg?.type || "",
					orgName: selectedOrg?.organization_name || "",
					orgId: selectedOrg?.id || "",
					memberCount: selectedOrg?.memberCount || 0,
				})
				.catch(err => {
					sentryCaptureException(
						"handleFormDataSubmit: signUpCompleted API failed on Installation Message",
						err,
					)
				})

		await updateContactSignUpCompleted
			.mutateAsync({
				email: formData.email,
			})
			.catch(err => {
				sentryCaptureException(
					"handleFormDataSubmit: updateSignUpCompleted API failed",
					err,
				)
			})

		const twclid = getCookie("twclid")

		if (twclid) {
			await updateTwitter
				.mutateAsync({
					twclid: twclid,
					isOrg: selectedOrg?.type === "Organization",
				})
				.catch(err => {
					sentryCaptureException(
						"handleFormDataSubmit: updateTwitter API failed",
						err,
					)
				})
		}

		const company = formData.work
		if (!company) {
			return
		}
		try {
			await updateContact.mutateAsync({
				email: chargebeeEmail,
				company: company,
			})
		} catch (err) {
			sentryCaptureException(
				"handleFormDataSubmit: updateContactCompany API failed",
				err,
			)
		}

		await updateHearAbout
			.mutateAsync({
				email: chargebeeEmail,
				hearAbout: formData.hearAbout,
				other: formData.specificSource,
			})
			.catch(err => {
				sentryCaptureException(
					"handleFormDataSubmit: updateContactHearAbout API failed",
					err,
				)
			})

		if (formData.email === chargebeeEmail) {
			return
		}

		setChargebeeEmail(formData.email)

		await axios
			.post(`${import.meta.env.VITE_BILLING_FUNC_URL}/updateOrgEmail`, {
				email: formData.email,
				organization_id: sessionStorage.getItem("org_id"),
			})
			.catch(err => {
				sentryCaptureException(
					"handleFormDataSubmit: updateEmail API failed",
					err,
				)
			})
	}

	function updateAzureToken(popupState: boolean) {
		setAzureUserPopup(popupState)
	}

	const isLoading =
		isLoadingRepos || isLoadingOrgs || repositoriesQuery.isLoading

	return (
		<>
			{isLoading ? (
				<Loader size="small" message={loaderMessage} />
			) : (
				<div className="container mx-auto px-8 pb-2 pt-7">
					<div className="w-full">
						<div className="flex items-center justify-between">
							<div>
								<div className="font-500 mb-2 font-inter text-2xl leading-8 text-foreground">
									Repositories
								</div>
								<div className="font-400 font-inter text-sm leading-5 text-muted-foreground">
									List of repositories accessible to CodeRabbit.
								</div>
							</div>

							{isGitHubCloud && (
								<a href={import.meta.env.VITE_GITHUB_APP_URL} rel="noreferrer">
									<ImageButton title="Add Repositories" />
								</a>
							)}
						</div>

						<div className="mb-4 mt-6 flex w-full justify-between gap-4">
							<div className="flex-1">
								<Search
									onSearch={handleSearch}
									placeholder="Repo not found? Search here…"
									showLabel={false}
									debounce
								/>
							</div>
							{(isGitlab || isAzureDevops) && groupList.length > 1 && (
								<div className="flex-1 lg:flex-initial">
									<div className="font-poppins text-sm text-crb-text-secondary">
										{isGitlab ? "Subgroup" : "Projects"}
									</div>
									<select
										id="group"
										name="group"
										value={selectedGroup}
										onChange={e => {
											setSelectedGroup(e.target.value)
											setPagination(prev => ({ ...prev, pageIndex: 0 }))
										}}
										className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 font-figtreeRegular font-normal shadow-sm transition placeholder:text-gray-400 focus:outline-none focus:ring-4 focus:ring-gray-300 lg:w-64"
									>
										{groupList.map(org => (
											<option
												value={org.provider_organization_id}
												key={org.provider_organization_id}
											>
												{getSubgroupName(
													org.organization_name,
													selectedOrg?.organization_name,
												)}
											</option>
										))}
									</select>
								</div>
							)}
						</div>

						<DataTable
							data={reposToShow}
							columns={columns({ onAddRepo: addRepo })}
							tableOptions={{
								manualPagination: true,
								pageCount,
								state: {
									pagination,
								},
								onPaginationChange: setPagination,
							}}
						/>
					</div>

					{popupOpen && (
						<PopupForm
							onClose={handleClosePopup}
							onSubmit={handleFormDataSubmit}
							email={chargebeeEmail}
							work={
								selectedOrg?.type === "Organization"
									? selectedOrg.organization_name
									: ""
							}
							step={isSelfHosted ? 2 : 1}
						/>
					)}

					{gitlabUserPopup && (
						<ChooseBotUserModal
							open={gitlabUserPopup}
							onOpenChange={state => {
								sessionStorage.setItem("gitlab-user-popup", state.toString())
								setGitlabUserPopup(state)
							}}
						/>
					)}

					{azureUserPopup && (
						<AzureBotUserModal
							open={azureUserPopup}
							onOpenChange={state => {
								updateAzureToken(state)
							}}
						/>
					)}
				</div>
			)}
		</>
	)
}
