import { clsx, type ClassValue } from "clsx"
import log from "loglevel"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}

const isDev = import.meta.env.DEV
const logger = log.getLogger("codeRabbit-ui")
logger.setLevel(isDev ? "debug" : "error")

export { logger }
