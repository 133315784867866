import {
	useEffect,
	useMemo,
	useReducer,
	useState,
	type FC,
	type PropsWithChildren,
} from "react"

import ErrorLayout from "@/components/Loader/ErrorLayout"
import Loader from "@/components/Loader/Loader"
import { logger } from "@/lib/utils"
import {
	ENABLED_GRAFANA,
	GRAFANA_APP_NAME,
	GRAFANA_PROVIDER_VIEWPORT_ID,
} from "./consts"
import {
	GrafanaContext,
	grafanaContextInitial,
	grafanaReducer,
	useGrafanaContext,
	type GrafanaContextProps,
	type GrafanaReducer,
} from "./grafana-context"
import { useRootGrafanaApp } from "./hooks"
import { fetchInterceptor } from "./intercept-request"
import { GrafanaUtils, type MicroAppData } from "./utils"

if (ENABLED_GRAFANA) {
	fetchInterceptor()
}

export const GrafanaProvider: FC<PropsWithChildren> = ({ children }) => {
	const [state, dispatch] = useReducer<GrafanaReducer>(
		grafanaReducer,
		grafanaContextInitial,
	)

	const value: GrafanaContextProps = useMemo(
		() => ({
			...state,
			action: dispatch,
		}),
		[state],
	)

	return (
		<GrafanaContext.Provider value={value}>
			<RootGrafanaApp>
				{isMounted => {
					if (!isMounted) return null
					return children
				}}
			</RootGrafanaApp>
		</GrafanaContext.Provider>
	)
}

interface RootGrafanaAppProps {
	children: (isMounted: boolean) => React.ReactNode
}

let mfeAppCopy: MicroAppData | null | undefined = undefined
const containerRef = document.getElementById(GRAFANA_PROVIDER_VIEWPORT_ID)

export const RootGrafanaApp: FC<RootGrafanaAppProps> = ({ children }) => {
	const { action, grafanaUtils } = useGrafanaContext()

	useEffect(() => {
		if (!action || !containerRef) return

		// store inside grafana context
		action({
			type: "SET_GRAFANA_PROVIDER",
			payload: {
				name: GRAFANA_APP_NAME,
				container: containerRef,
				entry: GrafanaUtils.DEFAULT_ENTRY,
				configuration: {
					singular: true,
					prefetch: true,
					sandbox: {
						experimentalStyleIsolation: true,
					},
				},
			},
		})
	}, [action])

	useRootGrafanaApp(containerRef).load(!!mfeAppCopy)

	const [isLoading, setIsLoading] = useState(true)
	const [isError, setIsError] = useState(false)

	const mfeApp = grafanaUtils?.appData

	useEffect(() => {
		if (!mfeApp?.app) {
			return
		}

		mfeApp.app.mountPromise
			.then(() => {
				logger.info("Grafana root app mounted successfully")
				setIsLoading(false)
				mfeAppCopy = mfeApp
			})
			.catch(() => {
				logger.error("Failed to mount mfe app. Analytics API not available.")
				setIsLoading(false)
				setIsError(true)
			})
	}, [mfeApp])

	useEffect(() => {
		if (mfeAppCopy?.app) {
			const utils = new GrafanaUtils()
			utils.setAppData(mfeAppCopy)
			action?.({
				type: "SET_GRAFANA_UTILS",
				payload: utils,
			})
			setIsLoading(false)
		}
	}, [])

	return (
		<>
			{isLoading && !isError && <Loader />}
			{isError && <ErrorLayout />}
			{children(!isLoading)}
		</>
	)
}
