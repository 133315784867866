import { logout } from "@/utils/logout"
import { VITE_GRAFANA_API_URL } from "./consts"

const windowFetch = typeof window !== "undefined" ? window.fetch : null

export const fetchInterceptor = () => {
	window.fetch = async function (...args) {
		const [url, options] = args
		if (!windowFetch) {
			return new Promise((_, reject) => {
				reject(new Error("window.fetch is not defined"))
			})
		}

		let newURL = ""

		if (url instanceof Request) {
			newURL = url.url
		} else if (url instanceof URL) {
			newURL = url.href
		} else if (typeof url === "string") {
			newURL = url
		}

		newURL = newURL.trim()

		// Check if the URL is a link and extract the path
		if (newURL.startsWith("http://") || newURL.startsWith("https://")) {
			const urlObj = new URL(newURL)
			if (urlObj.origin === window.location.origin) {
				newURL = urlObj.pathname + urlObj.search
			} else {
				newURL = ""
			}
		}

		newURL = newURL.replace(/^\//, "")

		// Perform the necessary checks and modifications
		if (newURL.startsWith("grafana") || newURL.startsWith("public")) {
			newURL = `${VITE_GRAFANA_API_URL}/${newURL}`
		} else {
			newURL = ""
		}

		const res = await windowFetch(newURL !== "" ? newURL : url, options)

		if (res.status === 401) {
			await logout()
		}

		return res
	}
}
