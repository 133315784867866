import { Button } from "@/components/ui/button"
import type { OrganizationData } from "@/typings"
import { useAllOrgs } from "@/utils/getAllOrgs"
import { useProvider } from "@/utils/providers"
import { getGitHubOauthUrl } from "@/utils/utils.ts"
import React, { useEffect, useState } from "react"
import { FaPlus } from "react-icons/fa"
import { useLocation, useNavigate } from "react-router"
import ImageButton from "../../components/ImageButton/ImageButton"
import Loader from "../../components/Loader/Loader"
import LogoFull from "../../svg/logo-full"

const Onboarding: React.FC = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const email: string = location.state || ""
	const [loader, setLoader] = useState<boolean>(true)

	const { isGitlab, isGitHubCloud } = useProvider()
	const { fetchOrganizations } = useAllOrgs()

	useEffect(() => {
		setLoader(true)
		void fetchOrganizations().finally(() => {
			setLoader(false)
		})
	}, [])

	const [selection, setSelection] = React.useState<OrganizationData>()

	const orgList = (
		JSON.parse(sessionStorage.getItem("org_info")!) as OrganizationData[] | null
	)?.filter(org => !org.parentId)

	return (
		<>
			<div className="flex flex-col items-center px-5 py-3">
				<LogoFull className="mx-auto my-10" />
				<div className="flex w-full max-w-5xl flex-col items-stretch gap-2 rounded-[20px] border-[1px] border-[#EAECF0] p-8 shadow-md">
					<div className="flex flex-wrap items-center justify-between gap-4">
						<div className="flex flex-col">
							<div className="mb-2 font-poppins text-xl font-semibold text-black">
								Organizations
							</div>
							<div className="text-gray-secondary text-poppins text-lg">
								Select an organization to continue
							</div>
						</div>

						{isGitHubCloud && (
							<a target="_blank" href={getGitHubOauthUrl()} rel="noreferrer">
								<ImageButton
									title="Add Organization"
									image={<FaPlus className="-ml-1 mr-1 inline" size={18} />}
								/>
							</a>
						)}
					</div>

					<div className="my-6 rounded-lg border-[1px] border-[#EAECF0]">
						<div className="mb-2 p-8 font-poppins text-lg font-semibold text-black">
							Available Organizations
						</div>
						<div className="h-full max-h-96 min-h-36 overflow-y-auto text-sm scrollbar-thin">
							{loader ? (
								<Loader />
							) : (
								<>
									{!orgList?.length && (
										<div className="mt-6 flex h-full w-full items-center justify-center text-center text-lg text-[#242424]">
											No organizations found.
											<br />
											{isGitlab &&
												"You need to be part of at least one group in GitLab to continue."}
										</div>
									)}
									<ul className="grid grid-cols-1 divide-y">
										{orgList?.map(org => (
											<li
												className={`text-md border-border-[#EAECF0] border-bs-[1px] block px-8 py-6 font-figtreeMedium text-[16px] hover:cursor-pointer ${selection?.provider_organization_id === org.provider_organization_id ? "bg-[#ffdac9]" : "hover:bg-[#FFE8DD]"}`}
												onClick={() => {
													setSelection(org)
												}}
												key={org.id}
											>
												{org.organization_name}
											</li>
										))}
									</ul>
								</>
							)}
						</div>
					</div>
					<Button
						variant="outline"
						disabled={!selection}
						onClick={() => {
							if (selection) {
								sessionStorage.setItem(
									"selected_org",
									JSON.stringify(selection),
								)
								sessionStorage.setItem("org_id", selection.id)
								navigate("/settings/repositories", {
									state: { email },
								})
							}
						}}
					>
						Continue
					</Button>
				</div>
			</div>
		</>
	)
}

export default Onboarding
