import { useFirebaseCtx } from "@/firebase"
import { ProAndProLegacyPlans } from "@/utils/constant"
import { useProvider } from "@/utils/providers"
import type React from "react"
import {
	LuBook,
	LuBookOpen,
	LuBuilding2,
	LuDatabase,
	LuDollarSign,
	LuFileBarChart2,
	LuGift,
	LuGitlab,
	LuHelpCircle,
	LuLayoutGrid,
	LuPuzzle,
	LuUsers2,
} from "react-icons/lu"
import { SiAzuredevops } from "react-icons/si"

import type { OrganizationData } from "@/typings"
export interface SidebarMenuItem {
	/** Name of the menu item which is shown in the sidebar */
	name: string
	/** Icon for the menu item */
	Icon: React.FC<{ className?: string }>
	/** Link or function that should be executed when the menu item is clicked */
	link?: string | (() => void) | undefined
	/** Submenu items of the menu item */
	items?:
		| {
				name: string
				link: string
				hide?: boolean | (() => boolean)
				beta?: boolean
		  }[]
		| undefined
	/** Alternate names and links for the menu item to be marked as active */
	alt?: { name: string; link: string }[]
	/** Whether to hide the menu item or not */
	hide?: boolean | (() => boolean)
	/** Whether to open the link in a new tab or not */
	newTab?: boolean
}

type AllowedPlans = (typeof ProAndProLegacyPlans)[number]

function isAllowedPlan(planId: string): planId is AllowedPlans {
	return (ProAndProLegacyPlans as readonly string[]).includes(planId)
}

export const useNavItems = (
	selectedOrg: OrganizationData | null,
): SidebarMenuItem[] => {
	const { isCRSelfHosted, isSelfHosted, isGitlab, isAzureDevops } =
		useProvider()
	const hide =
		selectedOrg?.planId === null ||
		(selectedOrg?.planId != null && !isAllowedPlan(selectedOrg.planId))

	return [
		{
			name: "Repositories",
			Icon: ({ className }) => <LuDatabase className={className} />,
			link: "/settings/repositories",
			alt: [{ name: "Repository settings", link: "/repository" }],
			hide: !!sessionStorage.getItem("firebaseTenantId"),
		},
		{
			name: "Dashboard",
			Icon: ({ className }) => <LuLayoutGrid className={className} />,
			link: "/dashboard",
			hide: hide,
		},
		{
			name: "Integrations",
			Icon: ({ className }) => <LuPuzzle className={className} />,
			link: "/integrations",
			hide: hide,
		},
		{
			name: "Reports",
			Icon: ({ className }) => <LuFileBarChart2 className={className} />,
			items: [
				/* { name: 'On Demand', link: '/reports/ondemand' }, */
				{ name: "Recurring", link: "/reports/recurring", beta: false },
			],
			hide: !import.meta.env.VITE_FEATURE_REPORTING_ENABLED || hide,
		},
		{
			name: "Learnings",
			Icon: ({ className }) => <LuBookOpen className={className} />,
			link: "/learnings",
		},
		{
			/**
			 * This for internal use only. Used to invite self-hosted user.
			 * Only CodeRabbit dev team can access this.
			 */
			name: "Invite Self-Hosted Admin",
			link: "/invite-self-hosted-admin",
			hide: () => {
				const { overrideRole } = useFirebaseCtx()
				return (
					sessionStorage.getItem("override_role")?.toLowerCase() !==
						"super_admin" && overrideRole?.toLowerCase() !== "super_admin"
				)
			},
			Icon: ({ className }) => <LuUsers2 className={className} />,
		},

		{
			name: "Organization Settings",
			Icon: ({ className }) => <LuBuilding2 className={className} />,
			items: [
				{ name: "Configuration", link: "/settings/organization" },
				{
					name: "API Keys",
					link: "/settings/api-keys",
					hide: !import.meta.env.VITE_FEATURE_API_KEYS_ENABLED,
				},
				{
					name: "GitLab User",
					link: "/settings/gitlab-user",
					hide: () => {
						return !isGitlab || isSelfHosted
					},
				},
				{
					name: "Azure User",
					link: "/settings/azure-user",
					hide: () => {
						return !isAzureDevops || isSelfHosted
					},
				},
			],
		},
		{
			name: "Subscription",
			Icon: ({ className }) => <LuDollarSign className={className} />,
			link: "/settings/subscription",
			hide: () => {
				return isSelfHosted || isCRSelfHosted
			},
		},
	]
}

declare global {
	interface Window {
		HubSpotConversations?: {
			widget: {
				load: () => void
				open: () => void
				close: () => void
				remove: () => void
				status: () => { loaded: boolean }
			}
		}
	}
}

export const BottomNavItems: SidebarMenuItem[] = [
	{
		name: "Account",
		Icon: ({ className }) => <LuUsers2 className={className} />,
		link: "/settings/account/subscription",
		hide: () => {
			const { isSelfHosted } = useProvider()
			return !isSelfHosted
		},
	},
	{
		name: "Refer and Earn",
		Icon: ({ className }) => <LuGift className={className} />,
		link: () => {
			// if (window.growsurf) {
			// 	window.growsurf.open()
			// }
		},
		hide: () => {
			const isActiveSubscription = sessionStorage.getItem("enable_referral")
			return (
				import.meta.env.VITE_ENABLE_REWARD_SIDEBAR !== "true" ||
				!isActiveSubscription
			)
		},
	},
	{
		name: "Docs",
		Icon: ({ className }) => <LuBook className={className} />,
		link: "https://docs.coderabbit.ai/",
		newTab: true,
	},
	{
		name: "Support",
		Icon: ({ className }) => <LuHelpCircle className={className} />,
		link: () => {
			if (window.HubSpotConversations?.widget) {
				const widgetStatus = window.HubSpotConversations.widget.status()

				if (widgetStatus.loaded) {
					window.HubSpotConversations.widget.close()
					window.HubSpotConversations.widget.remove()
				} else {
					window.HubSpotConversations.widget.load()
					window.HubSpotConversations.widget.open()
				}
			}
		},
	},
]

export const AccountNavItems: SidebarMenuItem[] = [
	{
		name: "Subscription",
		Icon: ({ className }) => <LuDollarSign className={className} />,
		link: "/settings/account/subscription",
	},
	// {
	// 	name: "API Keys",
	// 	Icon: ({ className }) => <LuKeyRound className={className} />,
	// 	link: "/settings/account/api-keys",
	// 	hide: !import.meta.env.VITE_FEATURE_API_KEYS_ENABLED,
	// },
	{
		name: "GitLab User",
		Icon: ({ className }) => <LuGitlab className={className} />,
		link: "/settings/account/gitlab-user",
		hide: () => {
			const { isGitlab } = useProvider()
			return !isGitlab
		},
	},
	{
		name: "Azure User",
		Icon: ({ className }) => <SiAzuredevops className={className} />,
		link: "/settings/account/azure-user",
		hide: () => {
			const { isAzureDevops } = useProvider()
			return !isAzureDevops
		},
	},
]
