import { Button } from "@/components/ui/button"
import { DataTableSort } from "@/components/ui/data-table-sort"
import type { ApiKey } from "@/typings/coderabbitHandler"
import { formatDate } from "@/utils/utils"
import type { ColumnDef } from "@tanstack/react-table"
import { FaRegTrashAlt } from "react-icons/fa"

interface ColumnProps {
	onDeleteApiKey: (report: ApiKeyTableRow) => void
}

export type ApiKeyTableRow = ApiKey

export const columns = ({
	onDeleteApiKey,
}: ColumnProps): ColumnDef<ApiKeyTableRow>[] => [
	{
		accessorKey: "api_key_name",
		header: ({ column }) => {
			return (
				<DataTableSort column={column}>
					<span className="text-muted-foreground">Name</span>
				</DataTableSort>
			)
		},
		cell: ({ row }) => row.original.api_key_name,
	},
	{
		accessorKey: "last_used_date",
		header: ({ column }) => {
			return (
				<DataTableSort column={column}>
					<span className="text-muted-foreground">Last used</span>
				</DataTableSort>
			)
		},
		cell: ({ row }) =>
			row.original.last_used_date
				? formatDate(row.original.last_used_date)
				: "Never",
	},
	{
		accessorKey: "createdAt",
		header: ({ column }) => {
			return (
				<DataTableSort column={column}>
					<span className="text-muted-foreground">Created at</span>
				</DataTableSort>
			)
		},
		cell: ({ row }) => formatDate(row.original.createdAt),
	},
	{
		id: "actions",
		cell: ({ row }) => {
			return (
				<div className="flex items-center justify-end gap-1">
					<Button
						variant="ghost"
						title="Delete"
						aria-label="Delete API Key"
						onClick={() => {
							onDeleteApiKey(row.original)
						}}
					>
						<FaRegTrashAlt className="text-red-500" />
					</Button>
				</div>
			)
		},
	},
]
