import type { OrganizationData } from "@/typings"

import { getSelectedOrg } from "@/utils/utils"
import { useEffect, useState } from "react"

export const useSelectedOrg = () => {
	const [selectedOrg, setSelectedOrg] = useState<OrganizationData | null>(() =>
		getSelectedOrg(),
	)

	useEffect(() => {
		const listener = () => {
			setSelectedOrg(getSelectedOrg())
		}

		window.addEventListener("selected_org_changed", listener)

		return () => {
			window.removeEventListener("selected_org_changed", listener)
		}
	}, [])

	return selectedOrg
}
