import type { ColumnFilter, ColumnFiltersState } from "@tanstack/react-table"
import { useCallback, useState } from "react"

export function useColumnFilter<TData>(initialData: ColumnFiltersState = []) {
	const [columnFilters, setColumnFilters] =
		useState<ColumnFiltersState>(initialData)

	const setColumnFilter = useCallback(
		(filterKey: keyof TData, value: ColumnFilter["value"]) => {
			setColumnFilters(columnFilters => {
				if (value === undefined) {
					return columnFilters.filter(filter => filter.id !== filterKey)
				}

				const filterExists = columnFilters.some(
					filter => filter.id === filterKey,
				)

				if (!filterExists) {
					return [...columnFilters, { id: filterKey.toString(), value }]
				}

				return columnFilters.map(filter => {
					if (filter.id === filterKey) {
						return { ...filter, value }
					}
					return filter
				})
			})
		},
		[],
	)

	return { columnFilters, setColumnFilters, setColumnFilter }
}
