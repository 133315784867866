import type { Column } from "@tanstack/react-table"

import { ChevronDown, ChevronUp, ChevronsUpDown } from "lucide-react"
import { useMemo } from "react"
import { Button } from "./button"

interface DataTableSortProps<TData, TValue> {
	column: Column<TData, TValue>
	children?: React.ReactNode
}

export function DataTableSort<TData, TValue>({
	column,
	children,
}: DataTableSortProps<TData, TValue>) {
	const isSorted = column.getIsSorted()

	const IconComponent = useMemo(() => {
		if (isSorted === "asc") {
			return ChevronUp
		}

		if (isSorted === "desc") {
			return ChevronDown
		}

		return ChevronsUpDown
	}, [isSorted])

	return (
		<Button
			variant="ghost"
			onClick={() => {
				column.toggleSorting()
			}}
			aria-label="Toggle sorting"
		>
			{children}
			<IconComponent className="ml-2 h-4 w-4" />
		</Button>
	)
}
