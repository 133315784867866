import type { Control, FieldPath, FieldValues } from "react-hook-form"
import { Switch } from "@/components/ui/switch.tsx"
import { FormField } from "@/components/ui/form.tsx"
import { FormControl } from "@mui/material"

export interface ToggleSwitchProps<
	T extends FieldValues,
	K extends FieldPath<T> = FieldPath<T>,
> {
	readonly label: string
	readonly checked?: boolean
	readonly text?: string
	readonly disabled?: boolean
	readonly name: K
	readonly control: Control<T>
	readonly onChange?: (newState: boolean) => void
}

const SettingsToggleSwitch = <T extends FieldValues>({
	label,
	text,
	name,
	disabled,
	control,
	onChange,
}: ToggleSwitchProps<T>) => {
	return (
		<div className="w-full">
			<div className="flex w-full flex-row justify-between">
				<div>
					<div
						className="font-500 font-inter not-italic leading-5 "
						id="label-email"
					>
						{label}
					</div>
					{text && (
						<p className="max-w-3xl pt-1 text-sm font-light not-italic leading-[20px] text-muted-foreground ">
							{text}
						</p>
					)}
				</div>
				<div className="ml-4 mt-4 sm:col-span-2 sm:mt-0">
					<FormField
						control={control}
						render={({ field }) => {
							return (
								<FormControl>
									<Switch
										checked={field.value}
										onCheckedChange={e => {
											if (onChange) {
												onChange(e)
											}
											field.onChange(e)
										}}
										disabled={disabled}
									/>
								</FormControl>
							)
						}}
						name={name}
					></FormField>
				</div>
			</div>
		</div>
	)
}

export default SettingsToggleSwitch
