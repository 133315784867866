import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { firebaseAuth, useFirebaseCtx } from "./firebase"
import { logger } from "./lib/utils"

interface ProtectedRoutesProps {
	readonly Component: React.ElementType
}

const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({ Component }) => {
	const navigate = useNavigate()
	const isFirebaseLogin = useFirebaseCtx().isAuthenticated

	const checkLogin = async () => {
		const login = sessionStorage.getItem("accessToken")

		if (login == null) {
			if (!isFirebaseLogin) {
				navigate("/login")
				return
			}

			await firebaseAuth.signOut()
			navigate("/sign-in-with-email")
		}
	}

	useEffect(() => {
		checkLogin().catch(e => {
			logger.error("Error checking login", e)
		})
	}, [])

	return <Component />
}

export default ProtectedRoutes
