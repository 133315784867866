/**
 *
 * Navigate user to the last route user was on.
 */
export function navigateToLastRoute() {
	const lastPage = window.localStorage.getItem("last_route")
	if (!lastPage) {
		return
	}
	window.localStorage.removeItem("last_route")
	window.location.assign(lastPage)
}
