import type { FC } from "react"

export const DescriptionLink: FC<{
	linkName: string | undefined
	link: string | undefined
}> = ({ linkName, link }) => (
	<a
		href={link}
		target="__blank"
		className="font-medium text-crb-primary hover:underline"
	>
		{linkName}
	</a>
)
