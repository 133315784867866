import { logger } from "@/lib/utils"
import { trpc } from "@/trpc"
import { getSelectedOrg } from "@/utils/utils"
import type { User } from "firebase/auth"
import { noop } from "lodash"
import {
	createContext,
	useContext,
	useEffect,
	useState,
	type Dispatch,
	type FC,
	type PropsWithChildren,
	type SetStateAction,
} from "react"
import { toast } from "react-toastify"
import { firebaseAuth } from "./firebase"
import { useCheckAuth, useCompleteSignInWithEmailLink } from "./hooks"
import { AskForEmailPopUp } from "./SignInWithEmailLinkForm"

export const SESSION_STORAGE_TENANT_ID = "firebaseTenantId"
export const TENANT_ID_HEADER = "x-tenant-id"
export interface FirebaseContext {
	firebaseUser: User | null
	token?: string | undefined
	isAuthenticated: boolean
	overrideRole?: string | undefined
	setOverrideRole: Dispatch<SetStateAction<string | undefined>>
}

export const FirebaseContext = createContext<FirebaseContext>({
	firebaseUser: null,
	isAuthenticated: false,
	setOverrideRole: noop,
})

export const useFirebaseCtx = () => {
	return useContext(FirebaseContext)
}

export const FirebaseProvider: FC<PropsWithChildren> = ({ children }) => {
	const [firebaseUser, setFirebaseUser] = useState<User | null>(null)
	const [token, setToken] = useState<string | undefined>(undefined)
	const [overrideRole, setOverrideRole] = useState<string | undefined>(
		undefined,
	)
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const { checkFirebaseAuth } = useCheckAuth()
	const { completeSignIn, askToConfirmEmail, setAskToConfirmEmail } =
		useCompleteSignInWithEmailLink()

	const trpcUtils = trpc.useUtils()

	useEffect(() => {
		// This will set the onAuthStateChanged listener
		const unSubscribe = checkFirebaseAuth((user, token) => {
			setFirebaseUser(user)
			setToken(token)
			const isAuthenticated = !!user && !!token
			setIsAuthenticated(isAuthenticated)
		})

		return unSubscribe
	}, [])

	useEffect(() => {
		const sessionToken = sessionStorage.getItem("accessToken")
		const selectedOrg = getSelectedOrg()
		const tenantId = getTenantId()

		if (!firebaseUser || !token || (selectedOrg && sessionToken) || !tenantId) {
			logger.info("Not exchanging token: ", {
				firebaseUser,
				token,
				selectedOrg,
				sessionToken,
				tenantId,
			})

			if (firebaseUser && token && !tenantId) {
				logger.info("tenantId is not found in session storage")
				sessionStorage.clear()
				void firebaseAuth
					.signOut()
					.then(() => {
						window.location.assign(`/sign-in-with-email`)
					})
					.catch(() => {
						logger.error("Failed to sign out firebase, redirecting to login")
					})
			}

			return
		}

		logger.info("Exchanging token: ", {
			firebaseUser,
			token,
			selectedOrg,
			sessionToken,
			tenantId,
		})

		void trpcUtils.exchangeFirebaseToken.exchange
			.fetch(token)
			.then(data => {
				sessionStorage.setItem("accessToken", data.accessToken)
				sessionStorage.setItem("auth-token", data.accessToken)
				sessionStorage.setItem("selected_org", JSON.stringify(data.orgData))
				window.dispatchEvent(new CustomEvent("selected_org_changed"))
				sessionStorage.setItem("org_info", JSON.stringify(data.allOrgs))
				sessionStorage.setItem(SESSION_STORAGE_TENANT_ID, tenantId)
				sessionStorage.setItem("provider", `"${data.orgData.provider}"`)
				sessionStorage.setItem("scope", "Organization")
				sessionStorage.setItem(
					"isCRSelfHosted",
					data.isCodeRabbitSelfHostedInstance ? "true" : "false",
				)
				sessionStorage.setItem("avatar_url", firebaseUser.photoURL || "")
				sessionStorage.setItem(
					"subscription_id",
					data.subscriberUser?.subscription_id.toString() || "",
				)
				sessionStorage.setItem(
					"subscriber_id",
					data.subscriber?.id.toString() || "",
				)
				sessionStorage.setItem("user_id", firebaseUser.uid)
				sessionStorage.setItem("login", firebaseUser.displayName || "")
				if (data.orgData.overrideRole) {
					setOverrideRole(data.orgData.overrideRole)
					sessionStorage.setItem("override_role", data.orgData.overrideRole)
				}
				sessionStorage.setItem("selfHostedDomain", data.orgData.host_url || "")
				window.location.assign("/learnings/?tenantId=" + tenantId)

				window.ko?.identify(firebaseUser.email, {
					name: firebaseUser.displayName,
					provider: data.orgData.provider,
					scope: data.subscription?.subscription_scope,
					organization: data.orgData.organization_name,
				})
			})
			.catch(e => {
				toast.error("Error signing in with email link. Please try again.")
				logger.error("Error exchanging token", e)
			})
	}, [token, firebaseUser])

	return (
		<FirebaseContext.Provider
			value={{
				firebaseUser,
				token,
				isAuthenticated,
				overrideRole,
				setOverrideRole,
			}}
		>
			<AskForEmailPopUp
				{...{
					onSubmit: completeSignIn,
					open: askToConfirmEmail,
					setOpen: setAskToConfirmEmail,
				}}
			/>
			{children}
		</FirebaseContext.Provider>
	)
}

export function getTenantId() {
	const queryParams = new URLSearchParams(window.location.search)
	return (
		sessionStorage.getItem(SESSION_STORAGE_TENANT_ID) ||
		queryParams.get("tenantId")
	)
}
