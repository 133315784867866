import React from "react"
import LogoFull from "../../svg/logo-full"
import "./Loader.css"

interface LoaderProps {
	readonly message?: string
	readonly isContained?: boolean
}

const ErrorLayout: React.FC<LoaderProps> = ({
	message = "Something went wrong",
	isContained = false,
}) => {
	return (
		<div
			className={
				!isContained
					? "page-loader"
					: "flex min-h-96 flex-col items-center justify-center"
			}
		>
			<div className="mx-auto flex w-10/12 flex-col items-center sm:w-full">
				<LogoFull width={350} />
				<h1 className="mt-4 font-poppins text-lg text-red-400 sm:text-xl">
					{message}
				</h1>
			</div>
		</div>
	)
}

export default ErrorLayout
