import { Button } from "@/components/ui/button"
import { DataTableSort } from "@/components/ui/data-table-sort"
import { Switch } from "@/components/ui/switch"
import type { RepoDetails } from "@/typings/githubActionsHandler"
import { useProvider } from "@/utils/providers"
import type { ColumnDef } from "@tanstack/react-table"
import { FiEdit } from "react-icons/fi"
import { Link } from "react-router-dom"

interface ColumnProps {
	onAddRepo: (repo: RepoTableRow) => void
}

export type RepoTableRow = RepoDetails

export const columns = ({
	onAddRepo,
}: ColumnProps): ColumnDef<RepoTableRow>[] => [
	{
		accessorKey: "name",
		header: ({ column }) => {
			return (
				<DataTableSort column={column}>
					<span className="text-muted-foreground">Name</span>
				</DataTableSort>
			)
		},
		cell: ({ row }) => row.original.name,
	},
	{
		id: "actions",
		cell: ({ row }) => {
			const { isGitlab, isAzureDevops } = useProvider()

			return (
				<div className="flex items-center justify-end gap-4">
					{(isAzureDevops || isGitlab) && (
						<Switch
							onCheckedChange={() => {
								onAddRepo(row.original)
							}}
							checked={row.original.isEnabled}
							disabled={row.original.isEnabled}
						/>
					)}
					<Link
						to={`/repository/${row.original.id}/settings?repo=${row.original.name}`}
					>
						<Button variant="ghost">
							<FiEdit size={18} />
						</Button>
					</Link>
				</div>
			)
		},
	},
]
